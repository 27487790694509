import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import UserForm from "./UserForm";
import { addUser } from "common/services/users.service";

import { toast } from "react-toastify";

const AddUser: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchUsers: () => void;
}> = ({ toggle, isModalOpen, fetchUsers }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      agency: {
        label: "",
        value: "",
      },
      country: {
        value: "",
        label: "",
      },
      role: {
        value: "",
        label: "",
      },
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      blocked: false,
    },
    validationSchema: Yup.object({
      agency: Yup.object().shape({
        value: Yup.number().required("Please select agency"),
      }),
      country: Yup.object().shape({
        value: Yup.string(),
      }),
      password: Yup.string()
        .required("Please enter password")
        .min(6, "Password must be atleast 6 characters"),
      confirmPassword: Yup.string()
        .min(6, "Password must be atleast 6 characters")
        .required("Please confirm password")
        .oneOf([Yup.ref("password")], "Passwords must match"),
      email: Yup.string()
        .required("Please enter email")
        .email("Please enter a valid email"),
      firstName: Yup.string().required("Please enter first name"),
      lastName: Yup.string(),
      role: Yup.object().shape({
        value: Yup.number().required("Please select role"),
      }),
    }),
    onSubmit: async (values) => {
      const data = {
        agency: values["agency"]["value"],
        country: values["country"]["value"],
        role: values["role"]["value"],
        email: values["email"],
        username: values["email"],
        firstName: values["firstName"],
        lastName: values["lastName"],
        password: values["password"],
        blocked: values["blocked"],
      };

      setLoading(true);
      try {
        await addUser(data);
        validation.resetForm();
        toggle();
        toast.success("User added successfully.", { autoClose: 500 });
        fetchUsers();
      } catch (e: any) {
        toast.error("Error while adding new user.", { autoClose: 500 });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Add User
      </ModalHeader>

      <UserForm
        validation={validation}
        isUpdate={false}
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default AddUser;
