import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import PerformanceRatingForm from "./PerformanceRatingForm";
import { updateContractorPerformanceRating } from "common/services/contractors.service";
import { useDispatch } from "react-redux";
import { getContractor as onGetContractor } from "slices/thunks";

const UpdatePerformanceRating: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  contractor: any;
}> = ({ toggle, isModalOpen, contractor }) => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const data = contractor?.performanceRatings || [];

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      performanceRatings: (data.length > 0 &&
        data.map((rating: any) => {
          return {
            id: rating?.id,
            performanceRatingType: {
              value: rating?.performanceRatingType?.id,
              label: rating?.performanceRatingType?.name,
            },
            description: rating?.description,
            agency: {
              value: rating?.ratedBy?.id,
              label: rating?.ratedBy?.name,
            },
            from: (rating?.from && new Date(rating.from)) || null,
            to: (rating?.to && new Date(rating.to)) || null,
          };
        })) || [
        {
          contractor: {
            value: "",
            label: "",
          },
          performanceRatingType: {
            value: "",
            label: "",
          },
          description: "",
          agency: {
            value: "",
            label: "",
          },
          from: null,
          to: null,
        },
      ],
    },
    validationSchema: Yup.object({
      performanceRatings: Yup.array().of(
        Yup.object({
          performanceRatingType: Yup.object().shape({
            value: Yup.number().required(
              "Please select contractor rating type"
            ),
          }),
          description: Yup.string(),
          agency: Yup.object().shape({
            value: Yup.number().required("Please select rated by"),
          }),
          from: Yup.string().required("Please select from date"),
          to: Yup.string().nullable(),
        })
      ),
    }),
    onSubmit: (values: any) => {
      const data = {
        id: contractor.id,
        data: values.performanceRatings.map((rating: any) => {
          return {
            id: rating.id || null,
            performanceRatingType: rating.performanceRatingType.value || null,
            description: rating.description,
            ratedBy: rating.agency.value || null,
            from: rating.from && rating.from?.toLocaleDateString("en-CA"),
            to: rating.to && rating.to?.toLocaleDateString("en-CA"),
          };
        }),
      };

      setLoading(true);
      const saveData = async () => {
        try {
          await updateContractorPerformanceRating(data);
          validation.resetForm();
          toggle();
          toast.success("Performance Rating Updated Successfully", {
            autoClose: 1000,
          });
        } catch (err: any) {
        } finally {
          setLoading(false);
          dispatch(
            onGetContractor({
              id: contractor?.id,
            })
          );
        }
      };
      saveData();
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Performance Rating
        <small
          color="light"
          className="badge bg-primary-subtle align-bottom ms-1 text-primary"
        >
          {validation?.values?.performanceRatings?.length || 0}
        </small>
      </ModalHeader>

      <PerformanceRatingForm
        validation={validation}
        isUpdate={false}
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdatePerformanceRating;
