import * as url from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

export const getContractors = (params: any) =>
  api.get(url.GET_CONTRACTORS, params);
export const getContractor = (id: any, params: any) => {
  const populate: any[] = [];
  if (!params?.populate) params.populate = populate;
  return api.get(url.GET_CONTRACTOR + "/" + id, params);
};
export const addContractor = (data: any) =>
  api.create(url.ADD_CONTRACTOR, data);
export const updateContractor = (data: any) =>
  api.update(url.UPDATE_CONTRACTOR + "/" + data.id, data);
export const deleteContractor = (data: any) =>
  api.delete(url.DELETE_CONTRACTOR + "/" + data.id, data);
export const importContractor = (data: any) =>
  api.create(url.IMPORT_CONTRACTOR, data);
export const exportContractorsToCSV = (params: any) =>
  api.get(url.EXPORT_CONTRACTORS_TO_CSV, params, {
    responseType: "blob",
  });

export const getAgencyContractors = (params: any) =>
  api.get(url.GET_AGENCY_CONTRACTORS, params);
export const getAgencyContractor = (id: any, params: any) =>
  api.get(url.GET_AGENCY_CONTRACTOR + "/" + id, params);
export const addAgencyContractor = (data: any) =>
  api.create(url.ADD_AGENCY_CONTRACTOR, data);
export const updateAgencyContractor = (data: any) =>
  api.update(url.UPDATE_AGENCY_CONTRACTOR + "/" + data.id, data);
export const deleteAgencyContractor = (data: any) =>
  api.delete(url.DELETE_AGENCY_CONTRACTOR + "/" + data.id, data);

export const getContractorSummary = (params: any) =>
  api.get(url.GET_CONTRACTOR_SUMMARY + "/" + params.id);

export const updateContractorSEMG = (data: any) =>
  api.update(
    url.UPDATE_CONTRACTOR_SEMG_MENTION + "/" + data.id + "/semg-mention",
    data
  );

export const updateContractorPerformanceRating = (data: any) =>
  api.update(
    url.UPDATE_CONTRACTOR_PERFORMANCE_RATING +
      "/" +
      data.id +
      "/performance-rating",
    data
  );

export const updateContractorBoardOfDirectors = (data: any) =>
  api.update(
    url.UPDATE_CONTRACTOR_BOARD_OF_DIRECTORS +
      "/" +
      data.id +
      "/board-of-directors",
    data
  );

export const updateContractorAffiliationsToPerson = (data: any) =>
  api.update(
    url.UPDATE_CONTRACTOR_AFFILIATIONS_TO_PERSON +
      "/" +
      data.id +
      "/affiliations-to-persons",
    data
  );

export const updateContractorAffiliationsToContractor = (data: any) =>
  api.update(
    url.UPDATE_CONTRACTOR_AFFILIATIONS_TO_CONTRACTOR +
      "/" +
      data.id +
      "/affiliations-to-contractors",
    data
  );

export const getOrganizationTypes = (params: any) =>
  api.get(url.GET_ORGANIZATION_TYPES, params);
export const getOrganizationType = (id: any, params: any) =>
  api.get(url.GET_ORGANIZATION_TYPE + "/" + id, params);
export const addOrganizationType = (data: any) =>
  api.create(url.ADD_ORGANIZATION_TYPE, data);
export const updateOrganizationType = (data: any) =>
  api.update(url.UPDATE_ORGANIZATION_TYPE + "/" + data.id, data);
export const deleteOrganizationType = (id: any) =>
  api.delete(url.DELETE_ORGANIZATION_TYPE + "/" + id);
