import React from "react";
import ImportokWizard from "@importok/react";
import { importRiskAssessments } from "common/services/risk-assessments.service";
import {
  getRiskAssessments,
  getRiskRatings,
} from "common/services/risk-assessments.service";
import { normalize } from "common/lib/normalize";
import { getAgencies } from "common/services/agencies.service";
import { getRegions } from "common/services/regions.service";
import { getContractors } from "common/services/contractors.service";
import { getUsers } from "common/services/users.service";
import * as moment from "moment";
import ImportRecord from "@importok/javascript/ImportRecord";

import style from "./import-styles";
import { Alert } from "reactstrap";

const RiskAssessmentsImport: React.FC<{}> = () => {
  const fields = {
    ReportTitle: {
      label: "Report Title",
      validators: "required|reportTitle",
      transformers: "capitalize|trim",
    },
    Contractor: {
      label: "Contractor",
      validators: "in:contractors|required",
      transformers: "trim",
      provider: "contractors",
    },
    RequestedBy: {
      label: "Requested By",
      validators: "required|in:agencies",
      transformers: "trim",
      provider: "agencies",
    },
    SerialNumber: {
      label: "Serial Number",
      validators: "serialNumber",
      transformers: "trim",
    },
    ConductedBy: {
      label: "Conducted By",
      validators: "in:users",
      transformers: "trim",
      provider: "users",
    },
    OverallRiskRating: {
      label: "Overall Risk Rating",
      validators: "required|in:overallRiskRatings",
      transformers: "trim",
      provider: "overallRiskRatings",
    },
    ReportDate: {
      label: "Report Date",
      validators: "date:dd/mm/yyyy",
      transformers: "trim",
    },
    SummaryOfFindings: {
      label: "Summary Of Findings",
      validators: "",
      transformers: "trim",
    },
    Classification: {
      label: "Classification",
      validators: "classification",
      transformers: "trim",
      provider: "classifications",
    },
  };

  const transformers = {};

  const validators = {
    reportTitle: function (record: ImportRecord, field: string) {
      const input = record.get(field);

      let errorMessage = "";
      return getRiskAssessments({
        filters: {
          reportTitle: {
            $eqi: input,
          },
        },
        fields: ["id"],
      }).then((response: any) => {
        const data = normalize(response.data);

        if (data && data.length > 0) {
          errorMessage = "Report title already exists";
        }
        return errorMessage || true;
      });
    },
    serialNumber: function (record: ImportRecord, field: string) {
      const input = record.get(field);

      let errorMessage = "";
      return getRiskAssessments({
        filters: {
          serialNumber: {
            $eqi: input,
          },
        },
        fields: ["id"],
      }).then((response: any) => {
        const data = normalize(response.data);

        if (data && data.length > 0) {
          errorMessage = "Serial number already exists";
        }
        return errorMessage || true;
      });
    },
    classification: function (record: ImportRecord, field: string) {
      const input = record.get(field);

      let errorMessage = "";
      if (input !== "Restricted" && input !== "Confidental") {
        errorMessage = "Invalid classification value";
      }
      return errorMessage || true;
    },
  };

  const providers = {
    contractors: {
      get: async function (query: any) {
        const response = await getContractors({
          filters: {
            contractorName: {
              $eqi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.contractorName,
          label: data?.[0]?.contractorName,
        };
      },
      find: async function (query: any) {
        const response = await getContractors({
          filters: {
            contractorName: {
              $containsi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.contractorName,
          label: item.contractorName,
        }));
      },
    },

    agencies: {
      get: async function (query: any) {
        const response = await getAgencies({
          filters: {
            name: query,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.name,
          label: data?.[0]?.name,
        };
      },
      find: async function (query: any) {
        const response = await getAgencies({
          filters: {
            name: {
              $containsi: query,
            },
            category: {
              $null: false,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.name,
          label: item.name,
        }));
      },
    },
    regions: {
      get: async function (query: any) {
        const splitQueries = query
          .split("|")
          .map((item: string) => item.trim());

        let data: any[] = [];
        for (let splitQuery of splitQueries) {
          const response = await getRegions({
            filters: {
              name: {
                $eqi: splitQuery,
              },
            },
          });

          if (!response.data) {
            throw new Error(`Response status ${response.status}`);
          }

          // data = normalize(response.data);
          data = [...data, ...normalize(response.data)];
          if (data.length === 0) {
            throw new Error(`Response status ${response.status}`);
          }
        }

        const label = data
          .map((item: any) => {
            return item?.name;
          })
          .join("|");
        const value = data
          .map((item: any) => {
            return item?.name;
          })
          .join("|");

        return {
          value: value,
          label: label,
        };
      },
      find: async function (query: any) {
        const splitQuery = query.split("|").map((item: string) => item.trim());

        //generate query for each splitQuery
        const orQuery = splitQuery.map((item: string) => {
          return {
            name: {
              $containsi: item,
            },
          };
        });

        const response = await getRegions({
          filters: {
            $or: orQuery,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }
        const newValues = data.filter((item: any) => {
          if (item.name && splitQuery.includes(item.name)) {
            return false;
          }
          return true;
        });

        const existingValues = data.filter((item: any) => {
          if (item.name && splitQuery.includes(item.name)) {
            return true;
          }
          return false;
        });

        return newValues.map((item: any) => {
          const pre = existingValues.map((item: any) => item?.name).join("|");
          const preIds = existingValues
            .map((item: any) => item?.name)
            .join("|");

          return {
            value: `${(preIds && preIds + "|" + item.name) || item.name}`,
            label: `${(pre && pre + "|" + item.name) || item.name}`,
          };
        });
      },
    },
    users: {
      get: async function (query: any) {
        const response = await getUsers({
          filters: {
            email: query,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.email,
          label: data?.[0]?.email,
        };
      },
      find: async function (query: any) {
        const response = await getUsers({
          filters: {
            email: {
              $containsi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.email,
          label: item.email,
        }));
      },
    },
    overallRiskRatings: {
      get: async function (query: any) {
        const response = await getRiskRatings({
          filters: {
            name: query,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.name,
          label: data?.[0]?.name,
        };
      },
      find: async function (query: any) {
        const response = await getRiskRatings({
          filters: {
            name: {
              $containsi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.name,
          label: item.name,
        }));
      },
    },
    classifications: {
      get: async function (query: any) {
        if (query === "Restricted") {
          return {
            value: "Restricted",
            label: "Restricted",
          };
        }
        if (query === "Confidental") {
          return {
            value: "Confidental",
            label: "Confidental",
          };
        }
        throw new Error(`Response status ${query}`);
      },
      find: async function (query: any) {
        return [
          {
            value: "Restricted",
            label: "Restricted",
          },
          {
            value: "Confidental",
            label: "Confidental",
          },
        ];
      },
    },
  };
  const [importErrors, setImportErrors] = React.useState<string[]>([]);
  const saveRecord = async function (record: any, meta: any) {
    try {
      const response = await importRiskAssessments({
        data: {
          reportTitle: record?.properties?.ReportTitle,
          contractor: record?.properties?.Contractor,
          agency: record?.properties?.RequestedBy,
          serialNumber: record?.properties?.SerialNumber,
          reportDate:
            (record?.properties?.ReportDate &&
              moment(record?.properties?.ReportDate, "DD/MM/YYYY")
                .toDate()
                .toLocaleDateString("en-CA")) ||
            null,
          overallRiskRating: record?.properties?.OverallRiskRating,
          location: record?.properties?.Location,
          classification: record?.properties?.Classification,
          summaryOfFindings: record?.properties?.SummaryOfFindings,
          conductedBy: record?.properties?.ConductedBy,
        },
      });
      return Promise.resolve(response);
    } catch (error: any) {
      if (error?.response?.data?.error?.message) {
        setImportErrors((state) => {
          return [...state, error?.response?.data?.error?.message];
        });
      }
      return Promise.reject(error);
    }
  };

  return (
    <>
      {importErrors.length > 0 && (
        <Alert color="danger" className="material-shadow">
          {importErrors.map((error: any) => (
            <div key={error}>{error}</div>
          ))}
        </Alert>
      )}

      <ImportokWizard
        title="Import Risk Assessments"
        fields={fields}
        transformers={transformers}
        // @ts-ignore
        validators={validators}
        providers={providers}
        onRecordReady={saveRecord}
        style={style}
      />
    </>
  );
};

export default RiskAssessmentsImport;
