import React, { useMemo, useEffect } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { createSelector } from "reselect";

import { getCountries as onGetCountries } from "../../slices/thunks";

const Country = ({
  validation,
  setLoading,
}: {
  validation: any;
  setLoading: any;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.country && validation.errors.country
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.country && validation.errors.country
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const dispatch: any = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      onGetCountries({
        pagination: {
          page: 1,
          pageSize: 10000,
        },
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, setLoading]);

  const selectLayoutState = (state: any) => state.Countries;
  const selectVehicleMakeProperties = createSelector(
    selectLayoutState,
    (state) => ({
      countries: state.countries.data,
    })
  );

  const { countries } = useSelector(selectVehicleMakeProperties);

  const countryOptions = useMemo(() => {
    return countries.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
  }, [countries]);

  return (
    <FormGroup>
      <Label htmlFor="country-field" className="form-label">
        Country Based In
      </Label>
      <Select
        name="country"
        id="country"
        value={
          validation.values.country.value ? validation.values.country : null
        }
        placeholder="Select country based in"
        onChange={(value: any) => {
          validation.setFieldValue("country", value);
        }}
        options={countryOptions}
        onBlur={() => validation.setFieldTouched("country", true)}
        className={
          validation.touched.country && validation.errors.country
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>{validation.errors.country?.value}</FormFeedback>
    </FormGroup>
  );
};

export default Country;
