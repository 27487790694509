import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import { addPvr } from "common/services/pvr.service";
import PVRForm from "./PVRForm";

const AddPvr: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;
}> = ({ toggle, isModalOpen, fetchData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      title: "",
      agency: {
        value: "",
        label: "",
      },
      contractor: {
        value: "",
        label: "",
      },
      verificationDate: null,
      conductedBy: {
        value: "",
        label: "",
      },
      summary: "",
      selectedFiles: [],
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter title"),
      agency: Yup.object().shape({
        value: Yup.number().required("Please select agency"),
      }),
      contractor: Yup.object().shape({
        value: Yup.number().required("Please select contractor"),
      }),
      vevrificationDate: Yup.string().nullable(),

      conductedBy: Yup.object().shape({
        value: Yup.number().required("Please select conducted by"),
      }),
      summary: Yup.string(),
      selectedFiles: Yup.array(),
      // .min(1, "Atleast one file is required")
      // .required("Required"),
    }),
    onSubmit: async (values: any) => {
      const data = {
        title: values.title,
        agency: values.agency.value,
        contractor: values.contractor.value,
        verificationDate:
          values.verificationDate &&
          values.verificationDate?.toLocaleDateString("en-CA"),
        conductedBy: values.conductedBy.value,
        summary: values.summary,
      };

      const formData = new FormData();
      values.selectedFiles.forEach((file: any) => {
        formData.append("files.pvrFile", file, file.name);
      });
      formData.append("data", JSON.stringify(data));

      try {
        setLoading(true);
        await addPvr(formData);
        fetchData();
        validation.resetForm();
        toggle();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Add Partner Verification Report
      </ModalHeader>

      <PVRForm
        validation={validation}
        isUpdate={false}
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default AddPvr;
