import React, { useEffect, useMemo, useState } from "react";
import { getPerformanceRatingTypes } from "common/services/performance-rating.service";
import { normalize } from "common/lib/normalize";

type RatingTypeFilterProps = {
  onChange: (value: any) => void;
  value: any;
};

const RatingTypeFilter = ({ onChange, value }: RatingTypeFilterProps) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getPerformanceRatingTypes({
          pagination: {
            page: 1,
            pageSize: 10000,
          },
        });

        setData(normalize(response.data));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading]);

  const options = useMemo(() => {
    return data.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
  }, [data]);

  return (
    <select
      className="form-control"
      data-choices
      data-choices-search-false
      name="choices-single-default2"
      id="choices-single-default2"
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
    >
      {loading && <option>Loading...</option>}
      <option value="">Select Performance Rating</option>
      {options.map((option: any) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default RatingTypeFilter;
