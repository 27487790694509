import { formatCurrencySuffix } from "common/lib/currency";
import React from "react";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { createSelector } from "reselect";

const ActiveLTAValue = () => {
  // Fetch data frm redux store

  const selectProperties = createSelector(
    (state: any) => state.Contracts,
    (state) => ({
      ltaSummary: state.ltaSummary,
    })
  );

  const { ltaSummary } = useSelector(selectProperties);

  const amount = formatCurrencySuffix(ltaSummary?.totalActiveContractValue);

  return (
    // <Card className="card-animate">
    //   <CardBody>
    //     <div className="d-flex align-items-center">
    //       <div className="flex-grow-1 overflow-hidden">
    //         <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
    //           Active LTA Value
    //         </p>
    //       </div>
    //     </div>
    //     <div className="d-flex align-items-end justify-content-between mt-4">
    //       <div>
    //         <h4 className="fs-22 fw-semibold ff-secondary mb-4">
    //           <span className="counter-value" data-target="559.25">
    //             <CountUp
    //               start={0}
    //               prefix="$"
    //               suffix={amount?.suffix}
    //               separator=""
    //               end={amount?.value}
    //               decimals={2}
    //               duration={4}
    //             />
    //           </span>
    //         </h4>
    //       </div>
    //       <div className="avatar-sm flex-shrink-0">
    //         <span className={"avatar-title rounded fs-3 bg-success-subtle"}>
    //           <i className={`text-success bx bx-dollar-circle`}></i>
    //         </span>
    //       </div>
    //     </div>
    //   </CardBody>
    // </Card>

    <Card className="card-animate">
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
            <p className="fw-medium text-muted mb-0">Active LTA Value</p>
            <h2 className="mt-4 ff-secondary fw-semibold fs-22">
              <span className="counter-value">
                <CountUp
                  start={0}
                  prefix="$"
                  suffix={amount?.suffix}
                  separator=""
                  end={amount?.value}
                  decimals={2}
                  duration={3}
                />
              </span>
            </h2>
            {/* <p className="mb-0 text-muted">
              <span className={"badge bg-light mb-0 text-success"}>
                <i className={"align-middle "}></i>{" "}
              </span>{" "}
              vs. previous month
            </p> */}
          </div>
          <div>
            <div className="avatar-sm flex-shrink-0">
              <span
                className={
                  "avatar-title rounded-circle fs-4 bg-success" +
                  "-subtle text-success"
                }
              >
                <i className={"bx bx-dollar-circle"}></i>
              </span>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ActiveLTAValue;
