import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import PerformanceRatingForm from "./PerformanceRatingForm";
import { addPerformanceRating } from "common/services/performance-rating.service";

const AddPerformanceRating: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;
}> = ({ toggle, isModalOpen, fetchData }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      performanceRatingType: {
        value: "",
        label: "",
      },
      description: "",
      agency: {
        value: "",
        label: "",
      },
      from: null,
      to: null,
      contractor: {
        value: "",
        label: "",
      },
    },
    validationSchema: Yup.object({
      performanceRatingType: Yup.object().shape({
        value: Yup.number().required("Please select contractor rating type"),
      }),
      description: Yup.string(),
      agency: Yup.object().shape({
        value: Yup.number().required("Please select rated by"),
      }),
      contractor: Yup.object().shape({
        value: Yup.number().required("Please select contractor"),
      }),
      from: Yup.string().required("Please select from date"),
      to: Yup.string().nullable(),
    }),
    onSubmit: (values: any) => {
      const data = {
        data: {
          performanceRatingType: values.performanceRatingType?.value || null,
          description: values.description,
          ratedBy: values.agency?.value || null,
          from: values.from && values?.from?.toLocaleDateString("en-CA"),
          to: values.to && values?.to?.toLocaleDateString("en-CA"),
          contractor: values.contractor?.value || null,
        },
      };

      setLoading(true);
      const saveData = async () => {
        try {
          await addPerformanceRating(data);
          validation.resetForm();
          toggle();
          fetchData();
          toast.success("Performance rating saved successfully", {
            autoClose: 1000,
          });
        } catch (err: any) {
        } finally {
          setLoading(false);
        }
      };
      saveData();
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Add Performance Rating
      </ModalHeader>

      <PerformanceRatingForm
        validation={validation}
        isUpdate={false}
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default AddPerformanceRating;
