import React, { useState, useMemo, useCallback } from "react";

import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Row,
  Card,
  Col,
  CardBody,
  CardHeader,
} from "reactstrap";

// RangeSlider
import "nouislider/distribute/nouislider.css";
import DeleteModal from "../../../Components/Common/DeleteModal";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import AddCapacityAssessment from "./AddCapacityAssessment";
import { getSortObj } from "common/lib/tableDataOptions";
import * as moment from "moment";
import {
  deleteCapacityAssessment,
  getCapacityAssessments,
} from "common/services/capacity-assessments.service";
import { normalize } from "common/lib/normalize";
import UpdateCapacityAssessment from "./UpdateCapacityAssessment";
import FileIcon from "Components/Common/FileIcon";
import Permission from "Components/Common/Permission";
import Permissions from "Components/Common/Permissions";
import Flatpickr from "react-flatpickr";
import SelectTypeFilter from "./SelectTypeFilter";
import Import from "./Import";

interface DateRange {
  fromDate: Date | null;
  toDate: Date | null;
}

interface FilterValue {
  dateRange: DateRange;
  capacityAssessmentType: any;
}

const CapacityAssessments = (props: any) => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1, // Starts from 1
    pageSize: Number(searchParams.get("pageSize")) || 20, // Default page size
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  // Update pageSize
  // const setPageSize = (newPageSize: number) => {
  //   setPagination((prevPagination) => ({
  //     ...prevPagination,
  //     pageSize: newPageSize,
  //   }));
  // };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });

  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    setPage(1);
    setSorted([{ id: "createdAt", desc: true }]);
    setSearchValue("");
    setFilterValue(defaultFilterValue);
  };

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const showAddModalForm = () => {
    setIsAddModalOpen(true);
  };

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModalForm = (contract: any) => {
    setSelectedRecord(contract);
    setIsUpdateModalOpen(true);
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const defaultFilterValue: FilterValue = {
    capacityAssessmentType: null,
    dateRange: {
      fromDate: null,
      toDate: null,
    },
  };
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const [filterValue, setFilterValue] = useState(() => {
    const filterParam = searchParams.get("filter");
    return filterParam ? JSON.parse(filterParam) : defaultFilterValue;
  });
  const [apiParams, setApiParams] = useState({});

  const onPageChange = useCallback(
    async ({
      page,
      sorted,
      searchValue,
      filterValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
      filterValue?: FilterValue;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      params.set("filter", JSON.stringify(filterValue));
      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);

      let andConditions = [];

      if (filterValue?.capacityAssessmentType?.value) {
        andConditions.push({
          typeOfAssessment: {
            id: filterValue?.capacityAssessmentType?.value,
          },
        });
      }

      if (filterValue?.dateRange?.fromDate && filterValue?.dateRange?.toDate) {
        andConditions.push({
          reportDate: {
            $gte: filterValue.dateRange.fromDate,
            $lte: filterValue.dateRange.toDate,
          },
        });
      }

      andConditions.push({
        $or: [
          {
            reportTitle: {
              $containsi: searchValue,
            },
          },
          {
            contractor: {
              contractorName: {
                $containsi: searchValue,
              },
            },
          },
        ],
      });

      try {
        setLoading(true);
        const apiParams = {
          pagination: {
            page,
            pageSize: pagination?.pageSize,
          },
          sort: sortObj,
          populate: [
            "contractor",
            "contractor.organizationType",
            "agency",
            "overallRating",
            "typeOfAssessment",
            "conductedBy",
            "reportFile",
          ],
          filters: {
            $and: andConditions,
          },
        };
        setApiParams(apiParams);
        const response: any = await getCapacityAssessments(apiParams);
        setData(normalize(response.data));
        setMeta(response.meta);
      } catch (err: any) {
        toast.error("Error while fetching data", {
          autoClose: 1000,
        });
      } finally {
        setLoading(false);
      }
    },
    [pagination?.pageSize, searchParams, setSearchParams]
  );

  const onClickDelete = useCallback(
    (riskAssessment: any) => {
      setSelectedRecord(riskAssessment);
      setDeleteModal(true);
    },
    [setSelectedRecord, setDeleteModal]
  );

  const fetchUpdatedData = useCallback(() => {
    onPageChange({
      page: pagination.pageIndex + 1,
      sorted: sorted,
      searchValue: searchValue,
    });
  }, [onPageChange, pagination.pageIndex, sorted, searchValue]);

  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    if (selectedRecord) {
      setDeleting(true);
      try {
        await deleteCapacityAssessment(selectedRecord);
        fetchUpdatedData();
        setDeleteModal(false);
        toast.success("Capacity assessment deleted successfully", {});
      } catch (err: any) {
        toast.error("Error while deleting capacity assessment", {
          autoClose: 1000,
        });
      } finally {
        setDeleting(false);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "Report Title",
        accessorKey: "contractor.contractorName",
        id: "contractor.contractorName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const rowData = cell.row.original;

          return (
            <>
              <div
                className="d-flex align-items-center"
                style={{
                  whiteSpace: "pre-wrap",
                  width: "550px",
                }}
              >
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light text-danger rounded fs-24">
                      <FileIcon
                        fileName={
                          rowData?.reportFile &&
                          Array.isArray(rowData?.reportFile) &&
                          rowData?.reportFile[0]?.name
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h5 className="fs-14 mb-1">
                    <Permission
                      alternative={
                        <Link to={"#"} className="text-body">
                          {cell.getValue()}
                        </Link>
                      }
                      action="findOne"
                      resource="capacity-assessment"
                    >
                      <Link
                        to={`/assessments/capacity-assessments/${rowData.id}`}
                        className="text-body"
                      >
                        {cell.getValue()}
                      </Link>
                    </Permission>
                  </h5>
                  <p className="text-muted mb-0">
                    Contractor:
                    <span className="fw-medium ms-1">
                      {cell.row.original?.contractor?.contractorName}
                    </span>
                  </p>
                </div>
              </div>
            </>
          );
        },
      },
      {
        header: "Type of Assessment",
        accessorKey: "typeOfAssessment.name",
        id: "typeOfAssessment.name",
        enableColumnFilter: false,
      },
      {
        header: "Overall Rating",
        accessorKey: "overallRating.name",
        id: "overallRating.name",
        enableColumnFilter: false,
      },

      {
        header: "UN Agency",
        accessorKey: "agency.name",
        id: "agency.name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
              }}
              className="text-truncate d-block"
            >
              {cell.row.original?.agency?.name || ""}
            </Link>
          );
        },
      },
      {
        header: "Assessment Date",
        accessorKey: "reportDate",
        id: "reportDate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },
      {
        header: "Action",
        cell: (cell: any) => {
          const rowData = cell.row.original;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm"
                tag="button"
              >
                <i className="ri-more-fill" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Permission action="findOne" resource="capacity-assessment">
                  <DropdownItem
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(
                        `/assessments/capacity-assessments/${rowData.id}`
                      );
                    }}
                  >
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                    View
                  </DropdownItem>
                </Permission>
                <Permission action="update" resource="capacity-assessment">
                  <DropdownItem
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      showUpdateModalForm(rowData);
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                    Edit
                  </DropdownItem>
                </Permission>

                <Permission action="delete" resource="capacity-assessment">
                  <DropdownItem divider />
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      onClickDelete(rowData);
                    }}
                  >
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                    Delete
                  </DropdownItem>
                </Permission>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [navigate, onClickDelete]
  );
  document.title = "Capacity Assessments | CIMS";

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} limit={1} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDelete()}
        onCloseClick={() => setDeleteModal(false)}
        loading={deleting}
      />
      <Container fluid>
        <BreadCrumb
          title="Capacity / Micro Assessments"
          pageTitles={[
            { title: "Dashboard", link: "/dashboard" },
            {
              title: "Assessments",
              link: "/assessments/capacity-assessments",
            },
          ]}
        />
        <Row>
          <Col xl={12} lg={12}>
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center g-3">
                  <Col md={7}>
                    <h5 className="card-title mb-0">
                      Capacity / Micro Assessments
                    </h5>
                  </Col>
                  <div className="col-md-auto ms-auto">
                    <div className="d-flex gap-2">
                      <Permission
                        resource="capacity-assessment"
                        action="create"
                      >
                        <Link
                          to="/#"
                          className="btn btn-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            showAddModalForm();
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i>
                          Add Capacity Assessment
                        </Link>
                      </Permission>
                      <Permissions
                        checks={[
                          {
                            resource: "capacity-assessment",
                            action: "import",
                          },
                          {
                            resource: "capacity-assessment",
                            action: "export",
                          },
                        ]}
                      >
                        <Import apiParams={apiParams} />
                      </Permissions>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <React.Fragment>
                    <CardBody className="border border-dashed border-end-0 border-start-0">
                      <form>
                        <Row>
                          <Col sm={4}>
                            <div
                              className={
                                "search-box me-2 mb-2 d-inline-block col-12"
                              }
                            >
                              <input
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  setPage(1);
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control search "
                                placeholder={"Search capacity assessments"}
                                value={searchValue || ""}
                              />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col sm={3}>
                            <SelectTypeFilter
                              filterValue={filterValue}
                              setFilterValue={(value: any) => {
                                setFilterValue({
                                  ...filterValue,
                                  capacityAssessmentType: value,
                                });
                                setPage(1);
                              }}
                            />
                          </Col>
                          <Col sm={3}>
                            {/* @ts-ignore */}
                            <Flatpickr
                              className="form-control"
                              placeholder="Select Date Range"
                              options={{
                                mode: "range",
                                altFormat: "d M, Y",
                                dateFormat: "d M, Y",
                              }}
                              onChange={(e: any) => {
                                if (e[0] && e[1])
                                  setFilterValue({
                                    ...filterValue,
                                    dateRange: {
                                      fromDate: e[0],
                                      toDate: e[1],
                                    },
                                  });
                                setPage(1);
                              }}
                              value={[
                                filterValue?.dateRange?.fromDate || "",
                                filterValue?.dateRange?.toDate || "",
                              ]}
                            />
                          </Col>

                          <Col sm={2}>
                            <button
                              color="primary"
                              className="btn btn-outline-primary material-shadow-none w-100"
                              onClick={(e) => {
                                e.preventDefault();
                                setSearchValue("");
                                setFilterValue(defaultFilterValue);
                                setPage(1);
                                setSorted([{ id: "createdAt", desc: true }]);
                              }}
                            >
                              <i className="ri-equalizer-line me-1 align-bottom"></i>
                              Reset Filters
                            </button>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </React.Fragment>
                </Row>
                <TableContainer
                  loading={loading}
                  columns={columns}
                  data={data || []}
                  pagination={pagination}
                  setPagination={setPagination}
                  sorted={sorted}
                  setSorted={setSorted}
                  onPageChange={onPageChange}
                  FilterSection={() => {}}
                  totalRows={meta?.pagination?.total || 0}
                  totalPages={meta?.pagination?.pageCount || 0}
                  searchValue={searchValue}
                  filterValue={filterValue}
                  divClass="table-responsive table-card mb-4"
                  tableClass="align-middle table-nowrap mb-0"
                  theadClass="table-light table-nowrap"
                />
                <AddCapacityAssessment
                  toggle={() => setIsAddModalOpen((state) => !state)}
                  isModalOpen={isAddModalOpen}
                  fetchData={fetchData}
                />
                <UpdateCapacityAssessment
                  toggle={() => setIsUpdateModalOpen((state) => !state)}
                  isModalOpen={isUpdateModalOpen}
                  selectedRecord={selectedRecord}
                  fetchUpdatedData={fetchUpdatedData}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CapacityAssessments;
