import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import PerformanceRatingForm from "./PerformanceRatingForm";
import { updatePerformanceRating } from "common/services/performance-rating.service";

const UpdatePerformanceRating: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;
  selectedRecord: any;
}> = ({ toggle, isModalOpen, fetchData, selectedRecord }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      contractor: {
        value: selectedRecord?.contractor?.id,
        label: selectedRecord?.contractor?.contractorName,
      },
      performanceRatingType: {
        value: selectedRecord?.performanceRatingType?.id,
        label: selectedRecord?.performanceRatingType?.name,
      },
      description: selectedRecord?.description,
      agency: {
        value: selectedRecord?.ratedBy?.id,
        label: selectedRecord?.ratedBy?.name,
      },
      from: (selectedRecord?.from && new Date(selectedRecord?.from)) || null,
      to: (selectedRecord?.to && new Date(selectedRecord?.to)) || null,
    },
    validationSchema: Yup.object({
      contractor: Yup.object().shape({
        value: Yup.number().required("Please select contractor"),
      }),
      performanceRatingType: Yup.object().shape({
        value: Yup.number().required("Please select contractor rating type"),
      }),
      description: Yup.string(),
      agency: Yup.object().shape({
        value: Yup.number().required("Please select rated by"),
      }),
      from: Yup.string().required("Please select from date"),
      to: Yup.string().nullable(),
    }),
    onSubmit: (values: any) => {
      const data = {
        id: selectedRecord.id,
        data: {
          id: values.id || null,
          contractor: values.contractor?.value || null,
          performanceRatingType: values.performanceRatingType?.value || null,
          description: values.description,
          ratedBy: values.agency?.value || null,
          from: values?.from && values?.from?.toLocaleDateString("en-CA"),
          to: values?.to && values?.to?.toLocaleDateString("en-CA"),
        },
      };

      setLoading(true);
      const saveData = async () => {
        try {
          await updatePerformanceRating(data);
          validation.resetForm();
          toggle();
          fetchData();
          toast.success("Performance rating updated successfully", {
            autoClose: 1000,
          });
        } catch (err: any) {
        } finally {
          setLoading(false);
        }
      };
      saveData();
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Performance Rating
      </ModalHeader>

      <PerformanceRatingForm
        validation={validation}
        isUpdate
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdatePerformanceRating;
