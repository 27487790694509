import DataImport from "pages/DataImport";
import React, { useCallback, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import dataImporterOptions from "common/constants/data-importers";
import ExportLoadingModal from "Components/Common/ExportLoadingModal";
import { exportRiskAssessmentsToCsv } from "common/services/risk-assessments.service";
import Permission from "Components/Common/Permission";
import { toast } from "react-toastify";

const defaultImporter = dataImporterOptions.find(
  (option: any) => option.value === "risk-assessments"
);

const Import = ({ apiParams }: { apiParams?: any }) => {
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const [exporting, setExporting] = useState(false);
  const getCsvExport = useCallback(async () => {
    setExporting(true);

    await exportRiskAssessmentsToCsv({
      filters: apiParams.filters,
      sort: apiParams.sort,
    })
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Risk_Assessments_Export.csv");
        link.click();
        link.remove();
      })
      .catch((error: any) => {
        toast.error("An error occurred while exporting risk assessments.");
      });

    setExporting(false);
  }, [apiParams]);
  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle
          tag="button"
          className="btn btn-light"
          id="dropdownMenuButton"
        >
          <i className="ri ri-more-fill"></i>
        </DropdownToggle>
        <DropdownMenu>
          <Permission resource="risk-assessment" action="import">
            <DropdownItem
              onClick={() => {
                setIsImportModalOpen(true);
              }}
            >
              <i className="ri ri-upload-2-line me-1"></i>
              Import
            </DropdownItem>
          </Permission>
          <Permission resource="risk-assessment" action="exportToCsv">
            <DropdownItem
              onClick={() => {
                getCsvExport();
              }}
            >
              <i className="ri ri-download-2-line me-1"></i>
              Export
            </DropdownItem>
          </Permission>
        </DropdownMenu>
      </UncontrolledDropdown>

      <DataImport
        toggle={() => setIsImportModalOpen(!isImportModalOpen)}
        isModalOpen={isImportModalOpen}
        defaultImporter={defaultImporter}
      />
      <ExportLoadingModal show={exporting} onCloseClick={() => {}} />
    </>
  );
};

export default Import;
