import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import ActiveLTAValue from "./ActiveLTAValue";
import UNAgencies from "./UNAgencies";
import Contractors from "./Contractors";
import { useDispatch } from "react-redux";
import { getLtaSummary } from "slices/thunks";
import NumberOfLtas from "./NumberOfLtas";

const LTASummaryWidgets = () => {
  const dispatch: any = useDispatch();
  useEffect(() => {
    dispatch(getLtaSummary({}));
  }, [dispatch]);
  return (
    <Row>
      <Col xl={3} md={6}>
        <ActiveLTAValue />
      </Col>
      <Col xl={3} md={6}>
        <UNAgencies />
      </Col>
      <Col xl={3} md={6}>
        <Contractors />
      </Col>
      <Col xl={3} md={6}>
        <NumberOfLtas />
      </Col>
    </Row>
  );
};

export default LTASummaryWidgets;
