import React from "react";
import ImportokWizard from "@importok/react";
import { getPvrs, importPvrs } from "common/services/pvr.service";
import { normalize } from "common/lib/normalize";
import { getAgencies } from "common/services/agencies.service";
import { getContractors } from "common/services/contractors.service";
import { getUsers } from "common/services/users.service";
import * as moment from "moment";
import ImportRecord from "@importok/javascript/ImportRecord";

import style from "./import-styles";
import { Alert } from "reactstrap";

const PVRImport: React.FC<{}> = () => {
  const fields = {
    Title: {
      label: "Title",
      validators: "required|reportTitle",
      transformers: "capitalize|trim",
    },
    Contractor: {
      label: "Contractor",
      validators: "in:contractors|required",
      transformers: "trim",
      provider: "contractors",
    },
    Agency: {
      label: "Agency",
      validators: "required|in:agencies",
      transformers: "trim",
      provider: "agencies",
    },
    SerialNumber: {
      label: "Serial Number",
      validators: "serialNumber",
      transformers: "trim",
    },
    ConductedBy: {
      label: "Conducted By",
      validators: "in:users",
      transformers: "trim",
      provider: "users",
    },
    VerificationDate: {
      label: "Verification Date",
      validators: "date:dd/mm/yyyy",
      transformers: "trim",
    },
    Summary: {
      label: "Summary",
      validators: "",
      transformers: "trim",
    },
  };

  const transformers = {};

  const validators = {
    reportTitle: function (record: ImportRecord, field: string) {
      const input = record.get(field);

      let errorMessage = "";
      return getPvrs({
        filters: {
          title: {
            $eqi: input,
          },
        },
        fields: ["id"],
      }).then((response: any) => {
        const data = normalize(response.data);

        if (data && data.length > 0) {
          errorMessage = "Report title already exists";
        }
        return errorMessage || true;
      });
    },
    serialNumber: function (record: ImportRecord, field: string) {
      const input = record.get(field);

      let errorMessage = "";
      return getPvrs({
        filters: {
          serialNumber: {
            $eqi: input,
          },
        },
        fields: ["id"],
      }).then((response: any) => {
        const data = normalize(response.data);

        if (data && data.length > 0) {
          errorMessage = "Serial number already exists";
        }
        return errorMessage || true;
      });
    },
  };

  const providers = {
    contractors: {
      get: async function (query: any) {
        const response = await getContractors({
          filters: {
            contractorName: {
              $eqi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.contractorName,
          label: data?.[0]?.contractorName,
        };
      },
      find: async function (query: any) {
        const response = await getContractors({
          filters: {
            contractorName: {
              $containsi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.contractorName,
          label: item.contractorName,
        }));
      },
    },

    agencies: {
      get: async function (query: any) {
        const response = await getAgencies({
          filters: {
            name: query,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.name,
          label: data?.[0]?.name,
        };
      },
      find: async function (query: any) {
        const response = await getAgencies({
          filters: {
            name: {
              $containsi: query,
            },
            category: {
              $null: false,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.name,
          label: item.name,
        }));
      },
    },

    users: {
      get: async function (query: any) {
        const response = await getUsers({
          filters: {
            email: query,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.email,
          label: data?.[0]?.email,
        };
      },
      find: async function (query: any) {
        const response = await getUsers({
          filters: {
            email: {
              $containsi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.email,
          label: item.email,
        }));
      },
    },
  };
  const [importErrors, setImportErrors] = React.useState<string[]>([]);
  const saveRecord = async function (record: any, meta: any) {
    try {
      const response = await importPvrs({
        data: {
          title: record?.properties?.Title,
          contractor: record?.properties?.Contractor,
          agency: record?.properties?.Agency,
          serialNumber: record?.properties?.SerialNumber,
          verificationDate:
            (record?.properties?.VerificationDate &&
              moment(record?.properties?.VerificationDate, "DD/MM/YYYY")
                .toDate()
                .toLocaleDateString("en-CA")) ||
            null,
          summary: record?.properties?.Summary,
          conductedBy: record?.properties?.ConductedBy,
        },
      });
      return Promise.resolve(response);
    } catch (error: any) {
      if (error?.response?.data?.error?.message) {
        setImportErrors((state) => {
          return [...state, error?.response?.data?.error?.message];
        });
      }
      return Promise.reject(error);
    }
  };

  return (
    <>
      {importErrors.length > 0 && (
        <Alert color="danger" className="material-shadow">
          {importErrors.map((error: any) => (
            <div key={error}>{error}</div>
          ))}
        </Alert>
      )}

      <ImportokWizard
        title="Import Partner Verification Reports"
        fields={fields}
        transformers={transformers}
        // @ts-ignore
        validators={validators}
        providers={providers}
        onRecordReady={saveRecord}
        style={style}
      />
    </>
  );
};

export default PVRImport;
