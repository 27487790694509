import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import * as moment from "moment";

const Header = ({ data }: { data: any }) => {
  return (
    <React.Fragment>
      <Col lg={12}>
        <Card className=" ">
          <div className="bg-warning-subtle">
            <CardBody className="pb-4 ">
              <Row>
                <div className="col-md">
                  <Row className="align-items-center">
                    <div className="col-md-auto">
                      <div className="avatar-md mb-md-0 mb-4 img-thumbnail rounded-circle">
                        <div className="avatar-title bg-primary-subtle rounded-circle fs-24">
                          <i className="ri-shield-user-line text-primary"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-md">
                      <h4 className="fw-semibold" id="ticket-title">
                        {`${data?.companyName || ""}`}
                      </h4>
                      <div className="hstack gap-3 flex-wrap">
                        <div className="text-muted text-capitalize">
                          <i className="ri-building-line align-bottom me-1"></i>{" "}
                          <span id="ticket-client">
                            {data?.dataSource || ""}
                          </span>
                        </div>
                        <div className="vr"></div>
                        {/* <div className="text-muted">
                          Date Added :{" "}
                          <span className="fw-medium" id="create-date">
                            {moment(new Date(data?.createdAt)).format(
                              "DD MMM, Y"
                            )}
                          </span>
                        </div>
                        <div className="vr"></div>
                        <div className="text-muted">
                          Last Updated :{" "}
                          <span className="fw-medium" id="due-date">
                            {moment(new Date(data?.updatedAt)).format(
                              "DD MMM, Y"
                            )}
                          </span>
                        </div>
                        <div className="vr"></div> */}
                      </div>
                    </div>
                  </Row>
                </div>
                <div className="col-md-auto mt-md-0 mt-4"></div>
              </Row>
            </CardBody>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Header;
