import React from "react";
import ImportokWizard from "@importok/react";
import style from "./import-styles";
import { getAgencies } from "common/services/agencies.service";
import {
  getPerformanceRatingTypes,
  importPerformanceRatings,
} from "common/services/performance-rating.service";
import { getContractors } from "common/services/contractors.service";
import { normalize } from "common/lib/normalize";
import * as moment from "moment";
import { Alert } from "reactstrap";

const PerformanceRatingsImport: React.FC<{}> = () => {
  const fields = {
    ContractorName: {
      label: "Contractor",
      validators: "in:contractors|required",
      transformers: "trim|as:contractors",
      provider: "contractors",
    },
    Rating: {
      label: "Performance Rating Type",
      validators: "required|in:performanceRatingTypes",
      transformers: "trim|as:performanceRatingTypes",
      provider: "performanceRatingTypes",
    },
    Reason: {
      label: "Reason",
      validators: "required",
      transformers: "trim",
    },
    RatedBy: {
      label: "Rated By",
      validators: "required|in:agencies",
      transformers: "trim|as:agencies",
      provider: "agencies",
    },
    From: {
      label: "From",
      validators: "date:dd/mm/yyyy|required",
      transformers: "trim",
    },
    To: {
      label: "To",
      validators: "date:dd/mm/yyyy",
      transformers: "trim",
    },
  };

  const transformers = {};

  const validators = {};

  const providers = {
    contractors: {
      get: async function (query: any) {
        const response = await getContractors({
          filters: {
            contractorName: {
              $eqi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data: any = normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.contractorName,
          label: data?.[0]?.contractorName,
        };
      },
      find: async function (query: any) {
        const response = await getContractors({
          filters: {
            contractorName: {
              $containsi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data: any = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.contractorName,
          label: item.contractorName,
        }));
      },
    },
    agencies: {
      get: async function (query: any) {
        const response = await getAgencies({
          filters: {
            name: query,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data: any = normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.name,
          label: data?.[0]?.name,
        };
      },
      find: async function (query: any) {
        const response = await getAgencies({
          filters: {
            name: {
              $containsi: query,
            },
            category: {
              $null: false,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data: any = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.name,
          label: item.name,
        }));
      },
    },
    performanceRatingTypes: {
      get: async function (query: any) {
        const response = await getPerformanceRatingTypes({
          filters: {
            name: query,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data: any = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.name,
          label: data?.[0]?.name,
        };
      },
      find: async function (query: any) {
        const response = await getPerformanceRatingTypes({
          filters: {
            name: {
              $containsi: query,
            },
            category: {
              $null: false,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data: any = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.name,
          label: item.name,
        }));
      },
    },
  };

  const [importErrors, setImportErrors] = React.useState<string[]>([]);

  const saveRecord = async function (record: any, meta: any) {
    try {
      const response = await importPerformanceRatings({
        data: {
          contractor: record?.properties?.ContractorName,
          performanceRatingType: record?.properties.Rating,
          ratedBy: record?.properties.RatedBy,
          from:
            (record?.properties.From &&
              moment(record?.properties.From, "DD/MM/YYYY")
                .toDate()
                .toLocaleDateString("en-CA")) ||
            null,
          to:
            (record?.properties.To &&
              moment(record?.properties.To, "DD/MM/YYYY")
                .toDate()
                .toLocaleDateString("en-CA")) ||
            null,
          description: record?.properties.Reason,
        },
      });
      return Promise.resolve(response);
    } catch (error: any) {
      if (error?.response?.data?.error?.message) {
        setImportErrors((state) => {
          return [...state, error?.response?.data?.error?.message];
        });
      }
      return Promise.reject(error);
    }
  };

  return (
    <>
      {importErrors.length > 0 && (
        <Alert color="danger" className="material-shadow">
          {importErrors.map((error: any) => (
            <div key={error}>{error}</div>
          ))}
        </Alert>
      )}

      <ImportokWizard
        title="Import Performance Ratings"
        fields={fields}
        transformers={transformers}
        validators={validators}
        providers={providers}
        onRecordReady={saveRecord}
        // @ts-ignore
        style={style}
      />
    </>
  );
};

export default PerformanceRatingsImport;
