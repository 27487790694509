import React, { useState, useMemo, useCallback } from "react";
import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { Link, useSearchParams } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb";
import TableContainer from "Components/Common/TableContainer";
import DeleteModal from "Components/Common/DeleteModal";
import * as moment from "moment";

import { toast } from "react-toastify";

import AddUser from "./AddUser";
import UpdateUser from "./UpdateUser";
import { getSortObj } from "common/lib/tableDataOptions";
import BlockUserModal from "Components/Common/BlockUserModal";
import SendPasswordResetModal from "Components/Common/SendPasswordResetModal";
import Permission from "Components/Common/Permission";
import {
  getUsers,
  deleteUser,
  activateUser,
  deactivateUser,
  sendPasswordResetLink,
} from "common/services/users.service";
import { normalize } from "common/lib/normalize";

const Users = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1, // Starts from 1
    pageSize: Number(searchParams.get("pageSize")) || 20, // Default page size
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  // Update pageSize
  // const setPageSize = (newPageSize: number) => {
  //   setPagination((prevPagination) => ({
  //     ...prevPagination,
  //     pageSize: newPageSize,
  //   }));
  // };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });

  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const [deleteModal, setDeleteModal] = useState(false);

  const fetchUsers = () => {
    onPageChange({
      page: 1,
      sorted: [{ id: "createdAt", desc: true }],
      searchValue: "",
    });
  };

  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setmeta] = useState<any>({});
  const onPageChange = useCallback(
    async ({
      page,
      sorted,
      searchValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);

      try {
        setLoading(true);
        const response: any = await getUsers({
          pagination: {
            page,
            pageSize: pagination.pageSize,
          },
          sort: sortObj,
          populate: [
            "role",
            "agency",
            "country",
            "countryOffice",
            "roles.permissions",
          ],
          filters: {
            $or: [
              {
                email: {
                  $containsi: searchValue,
                },
              },

              {
                firstName: {
                  $containsi: searchValue,
                },
              },
              {
                lastName: {
                  $containsi: searchValue,
                },
              },
              // {
              //   role: {
              //     name: {
              //       $containsi: searchValue,
              //     },
              //   },
              // },
            ],
          },
        });
        setData(normalize(response.data));
        setmeta(response.meta);
      } catch (e: any) {
        toast.error("Error while fetching data.", { autoClose: 500 });
      } finally {
        setLoading(false);
      }
    },
    [pagination.pageSize, searchParams, setSearchParams]
  );

  const fetchUpdatedUsers = useCallback(() => {
    onPageChange({
      page: pagination.pageIndex + 1,
      sorted: sorted,
      searchValue: searchValue,
    });
  }, [onPageChange, pagination.pageIndex, searchValue, sorted]);

  // Delete Data
  const onClickDelete = (users: any) => {
    setSelectedRecord(users);
    setDeleteModal(true);
  };
  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    if (selectedRecord) {
      setDeleting(true);
      try {
        await deleteUser(selectedRecord);
        setDeleting(false);
        toast.success("User deleted successfully.", { autoClose: 500 });
        fetchUpdatedUsers();
        setDeleteModal(false);
      } catch (e: any) {
        toast.error("Error while deleting data.", { autoClose: 500 });
      } finally {
        setDeleting(false);
      }
    }
  };

  const onClickActivate = (record: any, action: string) => {
    setSelectedRecord(record);
    setIsBlockModalOpen(action);
  };
  const [activating, setActivating] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState("");
  const handleActivateUser = async (action: string) => {
    if (action === "activate") {
      setActivating(true);
      try {
        await activateUser(selectedRecord);
        setActivating(false);
        fetchUpdatedUsers();
        setIsBlockModalOpen("");
      } catch (e: any) {
        toast.error("Error while activating user.", { autoClose: 500 });
      }
    } else if (action === "deactivate") {
      setActivating(true);
      try {
        await deactivateUser(selectedRecord);
        setActivating(false);
        fetchUpdatedUsers();
        setIsBlockModalOpen("");
      } catch (e: any) {
        toast.error("Error while deactivating user.", { autoClose: 500 });
      } finally {
        setActivating(false);
      }
    }
  };

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const showAddModalForm = () => {
    setIsAddModalOpen(true);
  };

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModalForm = (users: any) => {
    setSelectedRecord(users);
    setIsUpdateModalOpen(true);
  };

  const onClickSendPasswordReset = (record: any) => {
    setSelectedRecord(record);
    setIsPasswordResetModalOpen(true);
  };
  const [sendingPasswordReset, setSendingPasswordReset] = useState(false);
  const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] =
    useState(false);
  const handleSendPasswordReset = async (record: any) => {
    setSendingPasswordReset(true);
    try {
      await sendPasswordResetLink({
        email: record?.email,
      });
      setSendingPasswordReset(false);
      setIsPasswordResetModalOpen(false);
    } catch (e: any) {
      toast.error("Error while sending password reset link.", {
        autoClose: 500,
      });
    } finally {
      setSendingPasswordReset(false);
    }
  };

  //Column
  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "firstName",
        id: "firstName",
        enableColumnFilter: false,
        cell: (user: any) => (
          <>
            <div className="d-flex align-items-center">
              {user.row.original.img ? (
                <img
                  src={""}
                  alt=""
                  className="avatar-xs rounded-circle me-2"
                />
              ) : (
                <div className="flex-shrink-0 avatar-xs me-3">
                  <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-16">
                    <i className={"ri-user-line"}></i>
                  </div>
                </div>
              )}
              <Link to={`#`} className="fw-medium link-primary">
                {`${user?.row?.original?.firstName || ""} ${
                  user?.row?.original?.lastName || ""
                }`}
              </Link>
            </div>
          </>
        ),
      },
      {
        header: "Email",
        accessorKey: "email",
        id: "email",
        hidden: true,
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <Link to="#" className="fw-medium link-primary">
              {cell.getValue()}
            </Link>
          );
        },
      },

      {
        header: "Role",
        accessorKey: "role.name",
        id: "role.name",
        enableColumnFilter: false,
      },
      {
        header: "Agency",
        accessorKey: "agency.name",
        id: "agency.name",
        enableColumnFilter: false,
      },

      {
        header: "Status",
        accessorKey: "blocked",
        id: "blocked",
        enableColumnFilter: false,
        cell: (cell: any) => {
          if (cell.getValue() === false) {
            return (
              <span className="badge text-uppercase bg-success-subtle text-success">
                Active
              </span>
            );
          } else if (cell.getValue() === true) {
            return (
              <span className="badge text-uppercase bg-danger-subtle text-danger">
                Blocked
              </span>
            );
          } else {
            return (
              <span className="badge text-uppercase bg-warning-subtle text-warning">
                N/A
              </span>
            );
          }
        },
      },

      {
        header: "Last Updated",
        accessorKey: "updatedAt",
        id: "updatedAt",
        enableColumnFilter: false,
        cell: (cell) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          // const time = moment(new Date(cell?.getValue())).format("hh:mm A");
          return (
            <>
              {date}
              {/* <small className="text-muted">{time}</small> */}
            </>
          );
        },
      },

      {
        header: "Action",
        id: "action",
        cell: (cellProps: any) => {
          const rowData = cellProps.row.original;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm dropdown"
                tag="button"
              >
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end" end>
                {/* <DropdownItem href="/apps-invoices-details">
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                  View
                </DropdownItem> */}

                <Permission resource="user-management" action="update">
                  <DropdownItem
                    href="#"
                    onClick={() =>
                      showUpdateModalForm({
                        id: rowData.id,
                        ...rowData,
                      })
                    }
                  >
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                    Edit User
                  </DropdownItem>
                </Permission>
                {rowData?.blocked === false && (
                  <Permission resource="user-management" action="deactivate">
                    <DropdownItem
                      href="#"
                      onClick={() => {
                        onClickActivate(rowData, "deactivate");
                      }}
                    >
                      <i className="ri-shield-cross-fill align-bottom me-2 text-muted"></i>{" "}
                      Block User
                    </DropdownItem>
                  </Permission>
                )}

                {rowData?.blocked === true && (
                  <Permission resource="user-management" action="activate">
                    <DropdownItem
                      href="#"
                      onClick={() => {
                        onClickActivate(rowData, "activate");
                      }}
                    >
                      <i className="ri-shield-check-fill align-bottom me-2 text-muted"></i>{" "}
                      Unblock
                    </DropdownItem>
                  </Permission>
                )}

                <Permission
                  resource="users-permissions"
                  controller="auth"
                  action="forgotPassword"
                  category="plugin"
                >
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      onClickSendPasswordReset(rowData);
                    }}
                  >
                    <i className="ri-mail-send-fill align-bottom me-2 text-muted"></i>{" "}
                    Send Password Reset Email
                  </DropdownItem>
                </Permission>

                <Permission resource="user-management" action="delete">
                  <DropdownItem divider />

                  <DropdownItem
                    href="#"
                    onClick={() => {
                      onClickDelete({
                        id: rowData.id,
                        ...rowData.attributes,
                      });
                    }}
                  >
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                    Delete User
                  </DropdownItem>
                </Permission>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  document.title = "Users List | CIMS";

  return (
    <React.Fragment>
      <div className="page-content">
        <SendPasswordResetModal
          show={isPasswordResetModalOpen}
          onConfirmClick={() => handleSendPasswordReset(selectedRecord)}
          onCloseClick={() => setIsPasswordResetModalOpen(false)}
          loading={sendingPasswordReset}
          action={isBlockModalOpen}
        />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => handleDelete()}
          onCloseClick={() => setDeleteModal(false)}
          loading={deleting}
        />
        <BlockUserModal
          show={isBlockModalOpen}
          onConfirmClick={() => handleActivateUser(isBlockModalOpen)}
          onCloseClick={() => setIsBlockModalOpen("")}
          loading={activating}
          action={isBlockModalOpen}
        />

        <Container fluid>
          <BreadCrumb
            title="Users"
            pageTitles={[{ title: "Dashboard", link: "/dashboard" }]}
          />
          <Row>
            <Col lg={12}>
              <Card id="invoiceList">
                <CardHeader className="border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">Users</h5>
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-2 flex-wrap">
                        <Permission resource="user-management" action="create">
                          <Link
                            to="#"
                            className="btn btn-secondary"
                            onClick={(e) => {
                              e.preventDefault();
                              showAddModalForm();
                            }}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            Add User
                          </Link>
                        </Permission>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <Row className="mb-3">
                    <React.Fragment>
                      <CardBody className="border border-dashed border-end-0 border-start-0">
                        <form>
                          <Row>
                            <Col sm={5}>
                              <div
                                className={
                                  "search-box me-2 mb-2 d-inline-block col-12"
                                }
                              >
                                <input
                                  onChange={(e) => {
                                    setSearchValue(e.target.value);
                                  }}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control search /"
                                  placeholder={"Search user"}
                                  value={searchValue || ""}
                                />
                                <i className="bx bx-search-alt search-icon"></i>
                              </div>
                            </Col>

                            {searchValue.length > 0 ? (
                              <Col sm={4} xxl={1}>
                                <Button
                                  color="primary"
                                  className="w-400"
                                  style={{ paddingInline: 10 }}
                                  onClick={() => {
                                    setSearchValue("");
                                    setPage(1);
                                    setSorted([
                                      { id: "createdAt", desc: true },
                                    ]);
                                  }}
                                >
                                  <i className="ri-close-fill me-1 align-bottom"></i>
                                  Filters
                                </Button>
                              </Col>
                            ) : null}
                          </Row>
                        </form>
                      </CardBody>
                    </React.Fragment>
                  </Row>
                  <div>
                    <TableContainer
                      loading={loading}
                      columns={columns}
                      data={data || []}
                      pagination={pagination}
                      setPagination={setPagination}
                      sorted={sorted}
                      setSorted={setSorted}
                      onPageChange={onPageChange}
                      FilterSection={() => {}}
                      totalRows={meta?.pagination?.total || 0}
                      totalPages={meta?.pagination?.pageCount || 0}
                      searchValue={searchValue}
                      divClass="table-responsive table-card mb-4"
                      tableClass="align-middle table-nowrap mb-0"
                      theadClass="table-light table-nowrap"
                    />
                  </div>
                  <AddUser
                    toggle={() => setIsAddModalOpen((state) => !state)}
                    isModalOpen={isAddModalOpen}
                    fetchUsers={fetchUsers}
                  />
                  <UpdateUser
                    toggle={() => setIsUpdateModalOpen((state) => !state)}
                    isModalOpen={isUpdateModalOpen}
                    selectedRecord={selectedRecord}
                    fetchUpdatedUsers={fetchUpdatedUsers}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;
