import * as url from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

export const getContracts = (params: any) => api.get(url.GET_CONTRACTS, params);
export const getContract = (id: any, params: any) => {
  const populate = [
    "contractor",
    "subContractedTo",
    "contractRating",
    "contractType",
    "agency",
    "agency.mainLogoLight",
    "agency.mainLogo",
    "createdby",
    "createdby.agency",
    "updatedby",
    "updatedby.agency",
    "location",
  ];
  if (!params?.populate) params.populate = populate;
  return api.get(url.GET_CONTRACT + "/" + id, params);
};
export const addContract = (data: any) => api.create(url.ADD_CONTRACT, data);
export const importContract = (data: any) =>
  api.create(url.IMPORT_CONTRACT, data);
export const exportContractsToCSV = (params: any) =>
  api.get(url.EXPORT_CONTRACTS_TO_CSV, params, {
    responseType: "blob",
  });
export const updateContract = (id: any, data: any) =>
  api.put(url.UPDATE_CONTRACT + "/" + id, data);
export const deleteContract = (id: any) =>
  api.delete(url.DELETE_CONTRACT + "/" + id);
export const getContractDistributionByType = (params: any) =>
  api.get(url.GET_CONTRACT_DISTRIBUTION_BY_TYPE, params);
export const getContractDistributionByAgency = (params: any) =>
  api.get(url.GET_CONTRACT_DISTRIBUTION_BY_AGENCY, params);
export const getContractTotals = (params: any) =>
  api.get(url.GET_CONTRACT_TOTALS, params);
export const getContractDistributionByRegion = (params: any) =>
  api.get(url.GET_CONTRACT_DISTRIBUTION_BY_REGION, params);
export const getContractDistributionByRegionAndType = (params: any) =>
  api.get(url.GET_CONTRACT_DISTRIBUTION_BY_REGION_TYPE, params);
export const getLtaSummary = (params: any) =>
  api.get(url.GET_LTA_SUMMARY, params);

export const getAgencyContracts = (params: any) =>
  api.get(url.GET_AGENCY_CONTRACTS, params);
export const addAgencyContract = (data: any) =>
  api.create(url.ADD_AGENCY_CONTRACT, data);
export const deleteAgencyContract = (id: any) =>
  api.delete(url.DELETE_AGENCY_CONTRACT + "/" + id);
export const getAgencyActiveContractValue = (params: any) =>
  api.get(url.GET_AGENCY_ACTIVE_CONTRACT_VALUE, params);

export const getContractTypes = (params: any) =>
  api.get(url.GET_CONTRACT_TYPES, params);
export const getContractType = (id: any, params: any) =>
  api.get(url.GET_CONTRACT_TYPE + "/" + id, params);
export const addContractType = (data: any) =>
  api.create(url.ADD_CONTRACT_TYPE, data);
export const updateContractType = (id: any, data: any) =>
  api.put(url.UPDATE_CONTRACT_TYPE + "/" + id, data);
export const deleteContractType = (id: any) =>
  api.delete(url.DELETE_CONTRACT_TYPE + "/" + id);

export const getContractRatings = (params: any) =>
  api.get(url.GET_CONTRACT_RATINGS, params);
export const getContractRating = (id: any, params: any) =>
  api.get(url.GET_CONTRACT_RATING + "/" + id, params);
export const addContractRating = (data: any) =>
  api.create(url.ADD_CONTRACT_RATING, data);
export const updateContractRating = (id: any, data: any) =>
  api.put(url.UPDATE_CONTRACT_RATING + "/" + id, data);
export const deleteContractRating = (id: any) =>
  api.delete(url.DELETE_CONTRACT_RATING + "/" + id);
