import React from "react";
import CountUp from "react-countup";
import { Card, CardBody } from "reactstrap";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

const NumberOfLtas = () => {
  const selectProperties = createSelector(
    (state: any) => state.Contracts,
    (state) => ({
      ltaSummary: state.ltaSummary,
    })
  );

  const { ltaSummary } = useSelector(selectProperties);
  return (
    <Card className="card-animate">
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
            <p className="fw-medium text-muted mb-0">Number of Active LTAs</p>
            <h2 className="mt-4 ff-secondary fw-semibold fs-22">
              <span className="counter-value">
                <CountUp
                  start={0}
                  prefix={""}
                  suffix={""}
                  separator={""}
                  end={ltaSummary?.totalActiveContracts}
                  decimals={0}
                  duration={2}
                />
              </span>
            </h2>
            {/* <p className="mb-0 text-muted">
            <span className={"badge bg-light mb-0 text-success"}>
              <i className={"align-middle "}></i>{" "}
            </span>{" "}
            vs. previous month
          </p> */}
          </div>
          <div>
            <div className="avatar-sm flex-shrink-0">
              <span
                className={
                  "avatar-title rounded-circle fs-4 bg-primary" +
                  "-subtle text-primary"
                }
              >
                <i className={"bx bx-file"}></i>
              </span>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default NumberOfLtas;
