import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { CARD_MIN_HEIGHT } from "common/constants";

const MainDetails = ({ data }: { data: any }) => {
  return (
    <React.Fragment>
      <Col xxl={9}>
        <Card>
          <CardBody>
            <div className="text-muted">
              <h6 className="mb-2 fw-semibold text-uppercase">
                Sanction Details
              </h6>
            </div>
          </CardBody>
          <CardBody style={{ minHeight: CARD_MIN_HEIGHT }}>
            <div className="fs-13">
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Full Name:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.title || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Reference:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.uniqueID || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Designate Type:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.individualEntityShip || "Not Provided"}
                </Col>
              </Row>

              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Alias Names:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.aliasNames || "Not Provided"}
                </Col>
              </Row>

              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Designation Source:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.designationSource || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">OFSI Group Id:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.OFSIGroupId || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Gender:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.gender || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Non Latin Name:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.nonLatinName || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Phone Number:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.phoneNumber || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Email:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.email || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Titles:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.titles || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Date Designated:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.dateDesignated || "Not Provided"}
                </Col>
              </Row>

              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Passport:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.passport || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Passport Details:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.passportDetails || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Date of Birth:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.dateOfBirth || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Place of Birth:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.placeOfBirth || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Country of Birth:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.countryOfBirth || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Town of Birth:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.townOfBirth || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Address:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.fullAddress || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Citizenship:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.citizenship || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">National Identifier Number:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.nationalIdentifierNumber || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">
                    National Identifier Additional Information
                  </div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.nationalIdentifierAdditionalInformation ||
                    "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Additional Information:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.additionalInformation || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">UK Statements of Reason:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.ukStatementOfReasons || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Last Updated:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.lastUpdated || "Not Provided"}
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default MainDetails;
