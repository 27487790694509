import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import { addCapacityAssessment } from "common/services/capacity-assessments.service";
import CapacityAssessmentForm from "./CapacityAssessmentForm";

const AddCapacityAssessment: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;
}> = ({ toggle, isModalOpen, fetchData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      reportTitle: "",
      agency: {
        value: "",
        label: "",
      },
      contractor: {
        value: "",
        label: "",
      },
      capacityAssessmentType: {
        value: "",
        label: "",
      },
      capacityAssessmentRating: {
        value: "",
        label: "",
      },
      reportDate: null,

      conductedBy: {
        value: "",
        label: "",
      },
      // sensitivity: {
      //   value: "",
      //   label: "",
      // },
      summaryOfFindings: "",
      selectedFiles: [],
    },
    validationSchema: Yup.object({
      reportTitle: Yup.string().required("Please enter report title"),
      agency: Yup.object().shape({
        value: Yup.number().required("Please select agency"),
      }),
      contractor: Yup.object().shape({
        value: Yup.number().required("Please select contractor"),
      }),
      capacityAssessmentType: Yup.object().shape({
        value: Yup.number().required("Please select capacity assessment type"),
      }),
      capacityAssessmentRating: Yup.object().shape({
        value: Yup.number().required(
          "Please select capacity assessment rating"
        ),
      }),
      reportDate: Yup.string().nullable(),
      conductedBy: Yup.object().shape({
        value: Yup.number().required("Please select conducted by"),
      }),
      summaryOfFindings: Yup.string(),
      // sensitivity: Yup.object().shape({
      //   value: Yup.string().nullable(),
      // }),
      selectedFiles: Yup.array(),
      // .min(1, "Atleast one file is required")
      // .required("Required"),
    }),
    onSubmit: async (values: any) => {
      const data = {
        reportTitle: values.reportTitle,
        agency: values.agency.value,
        contractor: values.contractor.value,
        typeOfAssessment: values.capacityAssessmentType.value,
        overallRating: values.capacityAssessmentRating.value,
        reportDate:
          values.reportDate && values.reportDate.toLocaleDateString("en-CA"),
        conductedBy: values.conductedBy.value,
        summaryOfFindings: values.summaryOfFindings,
        // sensitivity: values.sensitivity.value,
      };

      const formData = new FormData();

      values.selectedFiles.forEach((file: any) => {
        formData.append("files.reportFile", file, file.name);
      });
      formData.append("data", JSON.stringify(data));

      try {
        setLoading(true);
        await addCapacityAssessment(formData);
        fetchData();
        validation.resetForm();
        toggle();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Add Capacity Assessment
      </ModalHeader>

      <CapacityAssessmentForm
        validation={validation}
        isUpdate={false}
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default AddCapacityAssessment;
