import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

// import SelectImporter from "./SelectImporter";
import ContractsImport from "./Contracts";
import ContractorsImport from "./Contractors";
import SEMGMentionsImport from "./SEMGMentions";
import BoardOfDirectorsImport from "./BoardOfDirectors";
import PerformanceRatingsImport from "./PerformanceRatings";
import PersonsImport from "./Persons";
import CapacityAssessmentsImport from "./CapacityAssessments";
import Permission from "Components/Common/Permission";
import RiskAssessmentImport from "./RiskAssessments";
import PartnerVerificationReport from "./PVR";

const DataImport: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  defaultImporter?: any;
}> = ({ toggle, isModalOpen, defaultImporter }) => {
  const [importer, setImporter] = useState<any>(null);

  useEffect(() => {
    if (defaultImporter) {
      setImporter(defaultImporter);
    }
  }, [defaultImporter]);

  return (
    <Modal
      id="data-import-modal"
      size="xl"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
      className="data-import-modal"
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Data Import - {importer?.label}
      </ModalHeader>
      <ModalBody>
        {/* <Row className="mb-4 align-items-end">
          <Col md={4}>
            <SelectImporter importer={importer} setImporter={setImporter} />{" "}
          </Col>
          <Col md={2} className="">
            <button
              className="btn btn-primary"
              onClick={() => {
                const oldImporter = {
                  ...importer,
                };
                if (importer) {
                  setImporter("");
                }
                setImporter(oldImporter);
              }}
            >
              Reset
            </button>
          </Col>
        </Row> */}

        <Permission resource="contract" action="import">
          {importer?.value === "contracts" && <ContractsImport />}
        </Permission>

        {importer?.value === "contractors" && <ContractorsImport />}
        {importer?.value === "performance-ratings" && (
          <PerformanceRatingsImport />
        )}
        {importer?.value === "semg-mentions" && <SEMGMentionsImport />}
        {importer?.value === "board-of-directors" && <BoardOfDirectorsImport />}

        {importer?.value === "persons" && <PersonsImport />}
        <Permission resource="capacity-assessment" action="import">
          {importer?.value === "capacity-assessments" && (
            <CapacityAssessmentsImport />
          )}
        </Permission>
        <Permission resource="risk-assessment" action="import">
          {importer?.value === "risk-assessments" && <RiskAssessmentImport />}
        </Permission>
        <Permission resource="partner-verification-report" action="import">
          <PartnerVerificationReport />
        </Permission>
      </ModalBody>
    </Modal>
  );
};

export default DataImport;
