import React, { useMemo, useEffect } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";
import { getAgencies } from "common/services/agencies.service";
import { normalize } from "common/lib/normalize";

const SelectAgency = ({
  validation,
  setLoading,
  label,
}: {
  validation: any;
  setLoading: any;
  label?: any;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched?.agency && validation.errors?.agency
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched?.agency && validation.errors?.agency
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const [data, setData] = React.useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response: any = await getAgencies({
          pagination: {
            page: 1,
            pageSize: 10000,
          },
          filters: {
            category: {
              $null: false,
            },
          },
        });
        setData(normalize(response.data));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading]);

  const options = useMemo(() => {
    return data.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
  }, [data]);

  return (
    <FormGroup>
      <Label htmlFor={`agency`} className="form-label">
        {label ? label : "Agency"}
      </Label>
      <Select
        name={`agency`}
        id={`agency`}
        value={
          validation.values?.agency?.value ? validation.values?.agency : null
        }
        placeholder="Select agency"
        onChange={(value: any) => {
          validation.setFieldValue(`agency`, value);
        }}
        options={options}
        onBlur={() => validation.setFieldTouched(`agency`, true)}
        className={
          validation.touched?.agency && validation.errors?.agency
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>{validation.errors?.agency?.value}</FormFeedback>
    </FormGroup>
  );
};

export default SelectAgency;
