import React, { useCallback, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";
import { getCountries as onGetCountries } from "../../slices/thunks";
import BreadCrumb from "../../Components/Common/BreadCrumb";

import { createSelector } from "reselect";
import { getSortObj } from "common/lib/tableDataOptions";
import * as moment from "moment";
import { useSearchParams } from "react-router-dom";

const Countries = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1, // Starts from 1
    pageSize: Number(searchParams.get("pageSize")) || 20, // Default page size
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  // Update pageSize
  // const setPageSize = (newPageSize: number) => {
  //   setPagination((prevPagination) => ({
  //     ...prevPagination,
  //     pageSize: newPageSize,
  //   }));
  // };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });

  const dispatch: any = useDispatch();

  const selectProperties = createSelector(
    (state: any) => state.Countries,
    (state) => ({
      countries: state.countries.data,
      meta: state.countries.meta,
      error: state.error,
      loading: state.loading,
    })
  );
  const { countries, meta, loading } = useSelector(selectProperties);

  const [searchValue, setSearchValue] = useState("");

  const onPageChange = useCallback(
    ({
      page,
      sorted,
      searchValue,
      filterValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
      filterValue?: any;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      params.set("filter", JSON.stringify(filterValue));
      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);

      dispatch(
        onGetCountries({
          pagination: {
            page,
            pageSize: pagination.pageSize,
          },
          sort: sortObj,
          populate: [],
          filters: {
            $or: [
              {
                name: {
                  $containsi: searchValue,
                },
              },
              {
                isoCode: {
                  $containsi: searchValue,
                },
              },
            ],
          },
        })
      );
    },
    [dispatch, pagination.pageSize, searchParams, setSearchParams]
  );
  const columns = useMemo(
    () => [
      //   {
      //     header: "#",
      //     accessorKey: "id",
      //     // cell: (cell: any) => <>{cell.getValue()}</>,
      //   },

      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "ISO Code",
        accessorKey: "isoCode",
        enableColumnFilter: false,
      },
      {
        header: "Phone Code",
        accessorKey: "phoneCode",
        enableColumnFilter: false,
      },
      {
        header: "Last Updated",
        accessorKey: "updatedAt",
        id: "updatedAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Countries"
            pageTitles={[
              { title: "Dashboard", link: "/dashboard" },
              {
                title: "Maps & Regions",
                link: "/countries",
              },
            ]}
          />
          <Card>
            <CardHeader>
              <Row className="align-items-center g-3">
                <Col md={3}>
                  <h5 className="card-title mb-0">Countries</h5>
                </Col>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex gap-2">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search countries..."
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                          setPage(1);
                        }}
                        value={searchValue}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                    {/* <button className="btn btn-success">
                      <i className="ri-equalizer-line align-bottom me-1"></i>
                      Filters
                    </button> */}
                  </div>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <TableContainer
                loading={loading}
                columns={columns}
                data={countries || []}
                pagination={pagination}
                setPagination={setPagination}
                sorted={sorted}
                setSorted={setSorted}
                onPageChange={onPageChange}
                FilterSection={() => {}}
                totalRows={meta?.pagination?.total || 0}
                totalPages={meta?.pagination?.pageCount || 0}
                searchValue={searchValue}
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Countries;
