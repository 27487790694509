import React from "react";
import ImportokWizard from "@importok/react";
import style from "./import-styles";
import { normalize } from "common/lib/normalize";
import { getContractors } from "common/services/contractors.service";
import { getAgencies } from "common/services/agencies.service";
import {
  getCapacityAssessmentTypes,
  getCapacityAssessmentRatings,
  importCapacityAssessments,
  getCapacityAssessments,
} from "common/services/capacity-assessments.service";
import { Alert } from "reactstrap";
import ImportRecord from "@importok/javascript/ImportRecord";
import moment from "moment";

const CapacityAssessmentsImport: React.FC<{}> = () => {
  const fields = {
    ReportTitle: {
      label: "Report Title",
      validators: "required|reportTitle",
      transformers: "capitalize|trim",
    },
    Contractor: {
      label: "Contractor",
      validators: "in:contractors|required",
      transformers: "trim",
      provider: "contractors",
    },
    Agency: {
      label: "Agency",
      validators: "required|in:agencies",
      transformers: "trim",
      provider: "agencies",
    },
    ReportDate: {
      label: "Report Date",
      validators: "date:dd/mm/yyyy",
      transformers: "trim",
    },
    ConductedBy: {
      label: "Conducted By",
      validators: "in:contractors",
      transformers: "trim",
      provider: "contractors",
    },
    TypeOfAssessment: {
      label: "Type of Assessment",
      validators: "in:typesOfAssessment",
      transformers: "trim",
    },
    OverallRating: {
      label: "Overall Rating",
      validators: "in:overallRatings",
      transformers: "trim",
    },
    // sensitivity: {
    //   label: "Sensitivity",
    //   validators: "",
    //   transformers: "trim",
    // },
    SummaryOfFindings: {
      label: "Summary of Findings",
      validators: "",
      transformers: "trim",
    },
  };

  const transformers = {};

  const validators = {
    reportTitle: function (record: ImportRecord, field: string) {
      const input = record.get(field);

      let errorMessage = "";
      return getCapacityAssessments({
        filters: {
          reportTitle: {
            $eqi: input,
          },
        },
        fields: ["id"],
      })
        .then((response: any) => {
          const data = normalize(response.data);

          if (data && data.length > 0) {
            errorMessage = "Contract title already exists";
          }
          return errorMessage || true;
        })
        .catch((error: any) => {
          console.error(error);
          return "An error occurred while validating the report title";
        });
    },
  };

  const providers = {
    contractors: {
      get: async function (query: any) {
        const response = await getContractors({
          filters: {
            contractorName: {
              $eqi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.contractorName,
          label: data?.[0]?.contractorName,
        };
      },
      find: async function (query: any) {
        const response = await getContractors({
          filters: {
            contractorName: {
              $containsi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.contractorName,
          label: item.contractorName,
        }));
      },
    },
    agencies: {
      get: async function (query: any) {
        const response = await getAgencies({
          filters: {
            name: query,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.name,
          label: data?.[0]?.name,
        };
      },
      find: async function (query: any) {
        const response = await getAgencies({
          filters: {
            name: {
              $containsi: query,
            },
            category: {
              $null: false,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.name,
          label: item.name,
        }));
      },
    },
    typesOfAssessment: {
      get: async function (query: any) {
        const response = await getCapacityAssessmentTypes({
          filters: {
            name: query,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.name,
          label: data?.[0]?.name,
        };
      },
      find: async function (query: any) {
        const response = await getCapacityAssessmentTypes({
          filters: {
            name: {
              $containsi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.name,
          label: item.name,
        }));
      },
    },

    overallRatings: {
      get: async function (query: any) {
        const response = await getCapacityAssessmentRatings({
          filters: {
            name: query,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.name,
          label: data?.[0]?.name,
        };
      },
      find: async function (query: any) {
        const response = await getCapacityAssessmentRatings({
          filters: {
            name: {
              $containsi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.name,
          label: item.name,
        }));
      },
    },
  };

  const [importErrors, setImportErrors] = React.useState<string[]>([]);

  const saveRecord = async function (record: any, meta: any) {
    try {
      const response = await importCapacityAssessments({
        data: {
          reportTitle: record?.properties?.ReportTitle,
          contractor: record?.properties?.Contractor,
          agency: record?.properties?.Agency,
          reportDate:
            record?.properties?.ReportDate &&
            moment(record?.properties?.ReportDate, "DD/MM/YYYY")
              .toDate()
              .toLocaleDateString("en-CA"),
          conductedBy: record?.properties?.ConductedBy,
          typeOfAssessment: record?.properties?.TypeOfAssessment,
          overallRating: record?.properties?.OverallRating,
          summaryOfFindings: record?.properties?.SummaryOfFindings,
        },
      });

      return Promise.resolve(response);
    } catch (error: any) {
      if (error?.response?.data?.error?.message) {
        setImportErrors((state) => {
          return [...state, error?.response?.data?.error?.message];
        });
      }
      return Promise.reject(error);
    }
  };

  return (
    <>
      {importErrors.length > 0 && (
        <Alert color="danger" className="material-shadow">
          {importErrors.map((error: any) => (
            <div key={error}>{error}</div>
          ))}
        </Alert>
      )}

      <ImportokWizard
        title="Import Capacity Assessments"
        fields={fields}
        transformers={transformers}
        // @ts-ignore
        validators={validators}
        providers={providers}
        onRecordReady={saveRecord}
        style={style}
      />
    </>
  );
};

export default CapacityAssessmentsImport;
