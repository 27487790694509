import { CARD_MIN_HEIGHT } from "common/constants";
import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

const MainDetails = ({ data }: { data: any }) => {
  return (
    <React.Fragment>
      <Col xxl={9}>
        <Card>
          <CardBody>
            <div className="text-muted">
              <h6 className="mb-2 fw-semibold text-uppercase">
                Sanction Details
              </h6>
            </div>
          </CardBody>
          <CardBody style={{ minHeight: CARD_MIN_HEIGHT }}>
            <div className="fs-13">
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Full Name:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.companyName || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Designate Type:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.dataSource || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Reference:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {"Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Alias Names:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.aliasNames || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Country:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.country || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Contact Name:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.contactName || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Contact Email:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.email || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Grounds:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.grounds || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Sanction Start Date:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.sanctionStartDates || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Sanction End Date:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.dateOfBirth || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Sanction Created Under MPF:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.sanctionCreatedUnderMPF || "Not Provided"}
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default MainDetails;
