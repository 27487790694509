import React, { useCallback, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import TableContainer from "../../../Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";
import {
  getContracts,
  deleteContract,
} from "common/services/contracts.service";
import AddContract from "../AddContract";
import { getSortObj } from "common/lib/tableDataOptions";
import * as moment from "moment";
import { isContractActive } from "common/lib/contractOptions";
import UpdateContract from "../UpdateContract";
import Permission from "Components/Common/Permission";
import Permissions from "Components/Common/Permissions";
import ContractTypeFilter from "../ContractTypeFilter";
import AgencyFilter from "../AgencyFilter";
import DeleteModal from "Components/Common/DeleteModal";
import { normalize } from "common/lib/normalize";
import { toast } from "react-toastify";
import Import from "../Import";
import BreadCrumb from "Components/Common/BreadCrumb";
import LTASummaryWidgets from "./LTASummaryWidgets";

interface DateRange {
  fromDate: Date | null;
  toDate: Date | null;
}

interface FilterValue {
  contractType: string;
  agency: string;
  dateRange: DateRange;
}

// @ts-ignore
const TextWrapper: React.FC<{ value: string }> = ({ value }) => {
  if (!value) return null;
  const matches = value.match(/.{1,40}/g);
  const wrappedText = matches ? matches.join("\n") : ""; // Handle null case
  return (
    <div style={{ whiteSpace: "pre-wrap", width: "220px" }}>{wrappedText}</div>
  );
};

const LTAs = ({
  isViewContractors,
  contractorId,
}: {
  isViewContractors?: boolean;
  isAgencyDashboard?: boolean;
  contractorId?: string;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1, // Starts from 1
    pageSize: Number(searchParams.get("pageSize")) || 20, // Default page size
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  // Update pageSize
  // const setPageSize = (newPageSize: number) => {
  //   setPagination((prevPagination) => ({
  //     ...prevPagination,
  //     pageSize: newPageSize,
  //   }));
  // };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });

  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const fetchContracts = () => {
    setPage(1);
    setSorted([{ id: "createdAt", desc: true }]);
    setSearchValue("");
    setFilterValue(defaultFilterValue);
  };

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const showAddModalForm = () => {
    setIsAddModalOpen(true);
  };

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModalForm = (contract: any) => {
    setSelectedRecord(contract);
    setIsUpdateModalOpen(true);
  };

  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const defaultFilterValue: FilterValue = {
    contractType: "",
    agency: "",
    dateRange: {
      fromDate: null,
      toDate: null,
    },
  };
  const [filterValue, setFilterValue] = useState(() => {
    const filterParam = searchParams.get("filter");
    return filterParam ? JSON.parse(filterParam) : defaultFilterValue;
  });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const onPageChange = useCallback(
    async ({
      page,
      sorted,
      searchValue,
      filterValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
      filterValue?: any;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      params.set("filter", JSON.stringify(filterValue));
      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);

      let andConditions = [];

      andConditions.push({
        isLTA: {
          $eq: true,
        },
      });

      if (filterValue?.agency) {
        andConditions.push({
          agency: {
            id: {
              $eq: filterValue.agency,
            },
          },
        });
      }
      if (filterValue?.contractType) {
        andConditions.push({
          contractType: {
            id: {
              $eq: filterValue.contractType,
            },
          },
        });
      }
      if (filterValue?.dateRange?.fromDate && filterValue?.dateRange?.toDate) {
        andConditions.push({
          $or: [
            {
              startDate: {
                $between: [
                  filterValue.dateRange.fromDate,
                  filterValue.dateRange.toDate,
                ],
              },
            },
            {
              endDate: {
                $between: [
                  filterValue.dateRange.fromDate,
                  filterValue.dateRange.toDate,
                ],
              },
            },
          ],
        });
      }

      andConditions.push({
        $or: [
          {
            contractTitle: {
              $containsi: searchValue,
            },
          },
          {
            contractor: {
              contractorName: {
                $containsi: searchValue,
              },
            },
          },
        ],
      });

      try {
        setLoading(true);

        const response: any = await getContracts({
          pagination: {
            page,
            pageSize: pagination.pageSize,
          },
          sort: sortObj,
          populate: [
            "agency",
            "contractType",
            "contractRating",
            "subContractedTo",
            "contractor",
            "location",
          ],
          filters: {
            $and: andConditions,
          },
        });
        setData(normalize(response.data));
        setMeta(response?.meta);
      } catch (e: any) {
        toast.error("Error while fetching data", {});
      } finally {
        setLoading(false);
      }
    },
    [pagination.pageSize, searchParams, setSearchParams]
  );

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = useCallback(
    (data: any) => {
      setSelectedRecord(data);
      setDeleteModal(true);
    },
    [setSelectedRecord, setDeleteModal]
  );

  const fetchUpdatedContracts = useCallback(() => {
    onPageChange({
      page: pagination.pageIndex + 1,
      sorted: sorted,
      searchValue: searchValue,
    });
  }, [onPageChange, pagination.pageIndex, sorted, searchValue]);

  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    if (selectedRecord) {
      setDeleting(true);
      try {
        await deleteContract(selectedRecord.id);
        fetchUpdatedContracts();
        setDeleting(false);
        setDeleteModal(false);
        toast.success("Contract deleted successfully");
      } catch (e: any) {
        toast.error("Error while deleting contract");
      } finally {
        setDeleting(false);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "Contract Title",
        accessorKey: "contractTitle",
        id: "contractTitle",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div style={{ whiteSpace: "pre-wrap", width: "300px" }}>
              <Link
                to={`/contracts/${cell.row.original?.id}`}
                className="fw-medium link-primary"
              >
                {cell.getValue()}
              </Link>
            </div>
          );
        },
      },
      // {
      //   header: "Contract Number",
      //   accessorKey: "contractNumber",
      //   enableColumnFilter: false,
      //   cell: (cell: any) => {
      //     return <FromCol {...cell} />;
      //   },
      // },
      {
        header: "Contractor Name",
        accessorKey: "contractor.contractorName",
        enableColumnFilter: false,
        id: "contractor.contractorName",
        cell: (cell: any) => <TextWrapper value={cell.getValue() || ""} />,
      },
      {
        header: "UN Agency",
        accessorKey: "agency.name",
        id: "agency.name",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => {
          const status = isContractActive(
            cell.row.original.startDate || "",
            cell.row.original.endDate || ""
          );
          return (
            <React.Fragment>
              {status === null ? (
                <span className="badge bg-warning-subtle text-warning fs-11">
                  N/A
                </span>
              ) : status ? (
                <span className="badge bg-success-subtle text-success fs-11">
                  Active
                </span>
              ) : status === false ? (
                <span className="badge bg-danger-subtle text-danger fs-11">
                  Not Active
                </span>
              ) : null}
            </React.Fragment>
          );
        },
      },
      {
        header: "Amount",
        accessorKey: "contractValue",
        enableColumnFilter: false,
        id: "contractValue",
        cell: (cell: any) => {
          return (
            <>
              {cell.getValue() ? `$ ${cell.getValue().toLocaleString()}` : ""}
            </>
          );
        },
      },

      {
        header: "Contract Type",
        accessorKey: "contractType.name",
        enableColumnFilter: false,
        id: "contractType.name",
      },

      {
        header: "Contract Start Date",
        accessorKey: "startDate",
        id: "startDate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          if (cell.getValue() === null) return null;
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },

      {
        header: "Contract End Date",
        accessorKey: "endDate",
        id: "endDate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          if (cell.getValue() === null) return null;
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },

      {
        header: "Action",
        enableSorting: false,
        cell: (cellProps: any) => {
          const rowData = cellProps.row.original;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm dropdown"
                tag="button"
              >
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Permission resource="contract" action="findOne">
                  <DropdownItem href={`/contracts/${rowData.id}`}>
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                    View
                  </DropdownItem>
                </Permission>

                <Permission resource="contract" action="update">
                  <DropdownItem
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      showUpdateModalForm({
                        id: rowData.id,
                        ...rowData,
                      });
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                    Edit
                  </DropdownItem>
                </Permission>

                <Permission resource="contract" action="delete">
                  <DropdownItem divider />
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      onClickDelete(rowData);
                    }}
                  >
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                    Delete
                  </DropdownItem>
                </Permission>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [onClickDelete]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="LTAs"
            pageTitles={[{ title: "Dashboard", link: "/dashboard" }]}
          />
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => handleDelete()}
            onCloseClick={() => setDeleteModal(false)}
            loading={deleting}
          />
          <LTASummaryWidgets />
          <Card>
            <CardBody>
              <Row className="g-3">
                <Col xxl={3} lg={6}>
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search LTAs..."
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                        setPage(1);
                      }}
                      value={searchValue}
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </Col>
                <Col xxl={2} lg={6}>
                  <ContractTypeFilter
                    onChange={(value) => {
                      setFilterValue({ ...filterValue, contractType: value });
                      setPage(1);
                    }}
                    value={filterValue.contractType}
                  />
                </Col>
                <Col xxl={3} lg={6}>
                  {/* @ts-ignore */}
                  <Flatpickr
                    className="form-control"
                    placeholder="Select Date Range"
                    options={{
                      mode: "range",
                      altFormat: "d M, Y",
                      dateFormat: "d M, Y",
                    }}
                    onChange={(e) => {
                      if (e[0] && e[1]) {
                        setFilterValue({
                          ...filterValue,
                          dateRange: {
                            fromDate: e[0],
                            toDate: e[1],
                          },
                        });
                        setPage(1);
                      }
                    }}
                    value={[
                      filterValue?.dateRange?.fromDate || "",
                      filterValue?.dateRange?.toDate || "",
                    ]}
                  />
                </Col>
                <Col xxl={2} lg={6}>
                  <AgencyFilter
                    onChange={(value) => {
                      setFilterValue({ ...filterValue, agency: value });
                      setPage(1);
                    }}
                    value={filterValue.agency}
                  />
                </Col>
                <Col xxl={2} lg={4}>
                  <button
                    onClick={() => {
                      setFilterValue(defaultFilterValue);
                      setSearchValue("");
                      setPage(1);
                      setSorted([{ id: "createdAt", desc: true }]);
                    }}
                    className="btn btn-outline-primary material-shadow-none w-100"
                  >
                    <i className="ri-equalizer-line align-bottom me-1"></i>{" "}
                    Reset Filters
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <Row className="align-items-center g-3">
                <Col md={9}>
                  <h5 className="card-title mb-1">Long Term Agreements</h5>
                  <p className="text-muted mb-0">
                    This is a list of Long Term Agreements (LTAs) established
                    with contractors, vendors, and implementing partners by
                    various UN agencies.
                  </p>
                </Col>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex gap-2">
                    <Permission resource="contract" action="create">
                      <Link
                        to="#"
                        className="btn btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          showAddModalForm();
                        }}
                      >
                        <i className="ri-add-line align-bottom me-1"></i>
                        Add LTA
                      </Link>
                    </Permission>

                    {/* <Permissions
                      checks={[
                        {
                          resource: "contract",
                          action: "import",
                        },
                        {
                          resource: "contract",
                          action: "export",
                        },
                      ]}
                    >
                      <Import />
                    </Permissions> */}
                  </div>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <TableContainer
                loading={loading}
                columns={columns}
                data={data || []}
                pagination={pagination}
                setPagination={setPagination}
                sorted={sorted}
                setSorted={setSorted}
                onPageChange={onPageChange}
                FilterSection={() => {}}
                totalRows={meta?.pagination?.total || 0}
                totalPages={meta?.pagination?.pageCount || 0}
                searchValue={searchValue}
                filterValue={filterValue}
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
              />
              <AddContract
                toggle={() => setIsAddModalOpen((state) => !state)}
                isModalOpen={isAddModalOpen}
                fetchContracts={fetchContracts}
                isLTA
              />
              <UpdateContract
                toggle={() => setIsUpdateModalOpen((state) => !state)}
                isModalOpen={isUpdateModalOpen}
                selectedRecord={selectedRecord}
                fetchContracts={fetchUpdatedContracts}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LTAs;
