import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import * as moment from "moment";
import { CARD_MIN_HEIGHT } from "common/constants";

const MainDetails = ({ data }: { data: any }) => {
  return (
    <React.Fragment>
      <Col xxl={9}>
        <Card>
          <CardBody>
            <div className="text-muted">
              <h6 className="mb-2 fw-semibold text-uppercase">
                Sanction Details
              </h6>
            </div>
          </CardBody>
          <CardBody style={{ minHeight: CARD_MIN_HEIGHT }}>
            <div className="fs-13">
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Full Name:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.title || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Designate Type:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.designateType || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Reference:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.reference || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Alias Names:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.aliasNames || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">First Name:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.firstName || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Last Name:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.lastName || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Desigation:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.designation || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Publish Date:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {(data?.publishDate &&
                    moment(data?.publishDate).format("DD MMM, Y")) ||
                    "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Program:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.program || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Date of Birth:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.dateOfBirth || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Place of Birth:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.placeOfBirth || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Address:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.address || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Nationality:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.nationality || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Citizenship:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.citizenship || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">
                    Identification Documents Information:
                  </div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.identificationDocumentsInformation || "Not Provided"}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Additional Information:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.additionalInformation || "Not Provided"}
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default MainDetails;
