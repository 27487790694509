import React, { useCallback, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

import { createSelector } from "reselect";
import { getEUSanctionsList as onGetSanctionsList } from "../../../slices/thunks";
import { getSortObj } from "common/lib/tableDataOptions";
import * as moment from "moment";

const EUSanctionsList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1, // Starts from 1
    pageSize: Number(searchParams.get("pageSize")) || 20, // Default page size
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  // Update pageSize
  // const setPageSize = (newPageSize: number) => {
  //   setPagination((prevPagination) => ({
  //     ...prevPagination,
  //     pageSize: newPageSize,
  //   }));
  // };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });

  const dispatch: any = useDispatch();

  const selectLayoutState: any = (state: any) => state.SanctionsList;
  const selectinvoiceProperties = createSelector(
    selectLayoutState,
    (state) => ({
      euSanctionsList: state.euSanctionsList.data,
      meta: state.euSanctionsList.meta,
      error: state.error,
      loading: state.loading,
    })
  );

  const { euSanctionsList, meta, loading } = useSelector(
    selectinvoiceProperties
  );
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );

  const onPageChange = useCallback(
    ({
      page,
      sorted,
      searchValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);

      dispatch(
        onGetSanctionsList({
          pagination: {
            page,
            pageSize: pagination.pageSize,
          },
          sort: sortObj,
          populate: [],
          filters: {
            $and: [
              {
                $or: [
                  {
                    title: {
                      $containsi: searchValue,
                    },
                  },
                  {
                    firstName: {
                      $containsi: searchValue,
                    },
                  },
                  {
                    middleName: {
                      $containsi: searchValue,
                    },
                  },
                  {
                    lastName: {
                      $containsi: searchValue,
                    },
                  },
                ],
              },
            ],
          },
        })
      );
    },
    [dispatch, pagination.pageSize, searchParams, setSearchParams]
  );

  const columns = useMemo(
    () => [
      //   {
      //     header: "#",
      //     accessorKey: "id",
      //     // cell: (cell: any) => <>{cell.getValue()}</>,
      //   },

      {
        header: "Full Name",
        accessorKey: "title",
        enableColumnFilter: false,
        id: "title",
        cell: (cell: any) => {
          const wrappedText = cell
            .getValue()
            .trim()
            .match(/.{1,80}/g)
            .join("\n");

          return (
            <div style={{ whiteSpace: "pre-wrap", width: "300px" }}>
              <Link
                to={`/sanctions-list/eu/${cell.row.original?.id}`}
                className="fw-medium link-primary"
              >
                {wrappedText}
              </Link>
            </div>
          );
        },
      },
      {
        header: "Reference",
        accessorKey: "reference",
        enableColumnFilter: false,
      },
      {
        header: "Programme",
        accessorKey: "programme",
        enableColumnFilter: false,
      },
      {
        header: "Alias Names",
        accessorKey: "aliasNames",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const value = cell.getValue();
          if (value && value.length > 40) {
            return <div className="fixed-table-column-width-2">{value}</div>;
          }
          return value;
        },
      },

      {
        header: "Last Updated At",
        accessorKey: "updatedAt",
        id: "updatedAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },
    ],
    []
  );

  document.title = "EU Sanctioned Item | CIMS";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <BreadCrumb title="EU Sanctions List" pageTitle="Sanctions List" /> */}

          <BreadCrumb
            title="EU Sanctions List"
            pageTitles={[
              { title: "Dashboard", link: "/dashboard" },
              {
                title: "Sanctions List",
                link: "/sanctions-list/eu",
              },
            ]}
          />

          <Card>
            <CardHeader>
              <Row className="align-items-center g-3">
                <Col md={9}>
                  <h5 className="card-title">EU Sanctions List</h5>
                  <p className="mb-0 text-muted">
                    This resource list consists of restrictive measures
                    (sanctions) introduced by the European Union (EU). The EU
                    applies sanctions to implement UN Security Council
                    Resolutions (UNSCR) or to further the objectives of the EU’s
                    common foreign and security policy (CFSP). Source:
                    <a
                      href="https://ec.europa.eu/info/business-economy-euro/banking-and-finance/international-relations/restrictive-measures-sanctions_en"
                      className="mx-1 text-primary"
                    >
                      EU Sanctions Website.
                    </a>
                  </p>
                </Col>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex gap-2">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search EU sanctions list..."
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                          setPage(1);
                        }}
                        value={searchValue}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                    {/* <button className="btn btn-success">
                      <i className="ri-equalizer-line align-bottom me-1"></i>
                      Filters
                    </button> */}
                  </div>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <TableContainer
                loading={loading}
                columns={columns}
                data={euSanctionsList || []}
                pagination={pagination}
                setPagination={setPagination}
                sorted={sorted}
                setSorted={setSorted}
                onPageChange={onPageChange}
                FilterSection={() => {}}
                totalRows={meta?.pagination?.total || 0}
                totalPages={meta?.pagination?.pageCount || 0}
                searchValue={searchValue}
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EUSanctionsList;
