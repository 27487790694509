import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import * as moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import UpdateAffiliationsToContractor from "./UpdateAffiliationsToContractor";
import { getPerson as onGetPerson } from "slices/thunks";
import Permission from "Components/Common/Permission";

const AffiliationsToContractor = ({ person }: { person: any }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  const contractorAffiliations = person?.contractorAffiliations || [];
  const contractorAffiliatedTo = person?.contractorAffiliatedTo || [];
  const totalAffiliations =
    contractorAffiliations.length + contractorAffiliatedTo.length;

  return (
    <Card>
      <CardHeader
        className={`d-flex align-items-center ${
          isOpen ? "border-bottom-dashed" : ""
        }`}
        onClick={toggleCollapse}
        style={{ cursor: "pointer" }}
      >
        <div className="text-muted flex-grow-1 d-flex align-items-center">
          <h6 className="mb-0 fw-semibold text-uppercase">
            Affiliations to Contractor
            <small
              color="light"
              className="badge bg-primary-subtle align-bottom ms-1 text-primary"
            >
              {totalAffiliations}
            </small>
          </h6>
        </div>
        <div className="flex-shrink-0 d-flex align-items-center">
          <Permission
            resource={"person"}
            action={"updateAffiliationsToContractors"}
          >
            <Link
              to="#"
              onClick={(e) => {
                e.stopPropagation();
                showUpdateModal();
              }}
              className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover me-3"
            >
              <i className="ri-pencil-line"></i> Edit
            </Link>
          </Permission>
        </div>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody className="card-body">
          <ListGroup flush>
            {contractorAffiliations.map((item: any, index: number) => (
              <ListGroupItem
                tag="a"
                to="#"
                className="list-group-item-action"
                onClick={() => {
                  navigate(`/contractors/${item?.contractor?.id}`);
                }}
                style={{ cursor: "pointer" }}
                key={index}
              >
                <div className="float-end">
                  <div>
                    <i className="ri-time-line align-bottom me-1"></i>{" "}
                    {moment(new Date(item?.updatedAt)).format("DD MMM, Y")}
                  </div>
                </div>
                <div className="d-flex mb-2 align-items-center">
                  <div className="flex-shrink-0">
                    <div className="avatar-sm  rounded">
                      <div className="avatar-title bg-primary-subtle text-primary rounded fs-24">
                        <i className="ri-building-line text-primary"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="list-title fs-15 mb-1">
                      {item?.contractor?.contractorName || ""}
                    </h5>
                    <p className="list-text mb-0 fs-12">
                      {item?.contractor?.organizationType?.name || ""}
                    </p>
                  </div>
                </div>
              </ListGroupItem>
            ))}

            {/* {contractorAffiliatedTo.map((item: any, index: number) => (
              <ListGroupItem
                tag="a"
                to="#"
                className="list-group-item-action"
                onClick={() => {
                  navigate(`/persons/${item?.person?.id}`);
                }}
                style={{ cursor: "pointer" }}
                key={index}
              >
                <div className="float-end">
                  <div>
                    <i className="ri-time-line align-bottom me-1"></i>{" "}
                    {moment(new Date(item?.updatedAt)).format("DD MMM, Y")}
                  </div>
                </div>
                <div className="d-flex mb-2 align-items-center">
                  <div className="flex-shrink-0">
                    <div className="avatar-sm  rounded">
                      <div className="avatar-title bg-primary-subtle text-primary rounded fs-24">
                        <i className="ri-building-line text-primary"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="list-title fs-15 mb-1">
                      {item?.person?.personName || ""}
                    </h5>
                    <p className="list-text mb-0 fs-12">
                      {item?.person?.organizationType?.name || ""}
                    </p>
                  </div>
                </div>
              </ListGroupItem>
            ))} */}
          </ListGroup>
        </CardBody>
      </Collapse>
      <UpdateAffiliationsToContractor
        isModalOpen={isUpdateModalOpen}
        toggle={() => setIsUpdateModalOpen(!isUpdateModalOpen)}
        data={person?.contractorAffiliations || []}
        fetchData={() => {
          dispatch(
            onGetPerson({
              id: person?.id,
            })
          );
        }}
        personId={person?.id}
      />
    </Card>
  );
};

export default AffiliationsToContractor;
