import SelectAgency from "./SelectAgency";
import React, { useEffect, useRef } from "react";
import {
  Col,
  FormFeedback,
  Input,
  Label,
  ModalBody,
  Row,
  Spinner,
  Form,
} from "reactstrap";
import SelectPerformanceRatingType from "./SelectPerformanceRatingType";
import Flatpickr from "react-flatpickr";
import SelectContractor from "pages/Contracts/SelectContractor";

const PerformanceRatingForm = ({
  validation,
  isUpdate,
  loading,
  setLoading,
  toggle,
}: {
  validation: any;
  isUpdate: boolean;
  loading: boolean;
  setLoading: (state: any) => void;
  toggle: () => void;
}) => {
  const simpleBarRef: any = useRef(null);

  useEffect(() => {
    if (simpleBarRef.current) {
      const simpleBarInstance = simpleBarRef.current.getScrollElement();
      simpleBarInstance.scrollTop = simpleBarInstance.scrollHeight;
    }
  }, []);

  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <ModalBody className="">
        <Row>
          <Col md={12} className="mb-3">
            <SelectContractor validation={validation} />
            <Row>
              <Col>
                <SelectAgency
                  validation={validation}
                  setLoading={setLoading}
                  label="Rated By"
                />
              </Col>

              <Col>
                <SelectPerformanceRatingType validation={validation} />
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <Label htmlFor={`from`} className="form-label">
                  From
                </Label>
                {/* @ts-ignore */}
                <Flatpickr
                  name={`from`}
                  id={`from`}
                  className={
                    validation.touched?.from && validation.errors?.from
                      ? "is-invalid form-control"
                      : "form-control"
                  }
                  placeholder="Select start date"
                  options={{
                    enableTime: false,
                    altInput: false,
                    altFormat: "d M, Y",
                    dateFormat: "d M, Y",
                  }}
                  onChange={(e) => {
                    if (e[0]) {
                      validation.setFieldValue(`from`, e[0]);
                      validation.setFieldError("from", "");
                    } else {
                      validation.setFieldValue(`from`, null);
                    }
                  }}
                  value={validation.values?.from || ""}
                />

                {validation.touched?.from && validation.errors?.from ? (
                  <FormFeedback type="invalid">
                    {validation.errors?.from}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col md={6} className="mb-3">
                <Label htmlFor={`to`} className="form-label">
                  To
                </Label>
                {/* @ts-ignore */}
                <Flatpickr
                  name={`to`}
                  id={`to`}
                  className="form-control"
                  placeholder="Select end date"
                  options={{
                    enableTime: false,
                    altInput: false,
                    altFormat: "d M, Y",
                    dateFormat: "d M, Y",
                  }}
                  onChange={(e) => {
                    validation.setFieldValue(`to`, e[0]);
                  }}
                  value={validation.values?.to || ""}
                />

                {validation.touched?.to && validation.errors?.to ? (
                  <FormFeedback type="invalid">
                    {validation.errors?.to}
                  </FormFeedback>
                ) : null}
              </Col>

              <Col md={12} className="mb-3">
                <Label htmlFor={`description`} className="form-label">
                  Reason/Description
                </Label>
                <Input
                  name={`description`}
                  id={`description`}
                  className="form-control"
                  placeholder="Enter description"
                  type="textarea"
                  rows={5}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                  invalid={
                    validation.touched?.description &&
                    validation.errors?.description
                      ? true
                      : false
                  }
                />
                {validation.touched?.description &&
                validation.errors?.description ? (
                  <FormFeedback type="invalid">
                    {validation.errors.description}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>

      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading && (
              <Spinner
                color="light"
                size="sm"
                style={{ marginRight: "8px", marginBottom: "-1px" }}
              ></Spinner>
            )}
            {isUpdate ? "Update Rating" : " Add Rating"}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default PerformanceRatingForm;
