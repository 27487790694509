import * as url from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

export const getRiskAssessments = (params: any) =>
  api.get(url.GET_RISK_ASSESSMENTS, params);
export const getRiskAssessment = (id: any, params: any) => {
  const populate = [
    "conductedBy",
    "reportFile",
    "createdby",
    "createdby.agency",
    "updatedby",
    "agency",
    "overallRiskRating",
    "contractor",
  ];
  if (!params?.populate) params.populate = populate;
  return api.get(url.GET_RISK_ASSESSMENT + "/" + id, params);
};
export const addRiskAssessment = (data: any) =>
  api.createAndUpload(url.ADD_RISK_ASSESSMENT, data);
export const updateRiskAssessment = (data: any) =>
  api.updateAndUpload(
    url.UPDATE_RISK_ASSESSMENT + "/" + data.id,
    data.formData
  );
export const deleteRiskAssessment = (data: any) =>
  api.delete(url.DELETE_RISK_ASSESSMENT + "/" + data.id, data);
export const importRiskAssessments = (data: any) =>
  api.create(url.IMPORT_RISK_ASSESSMENTS, data);
export const exportRiskAssessmentsToCsv = (params: any) =>
  api.get(url.EXPORT_RISK_ASSESSMENTS_TO_CSV, params);

// Risk Ratings
export const getRiskRatings = (params: any) =>
  api.get(url.GET_RISK_RATINGS, params);
export const getRiskRating = (id: any, params: any) =>
  api.get(url.GET_RISK_RATING + "/" + id, params);
export const addRiskRating = (data: any) =>
  api.create(url.ADD_RISK_RATING, data);
export const updateRiskRating = (data: any) =>
  api.update(url.UPDATE_RISK_RATING + "/" + data.id, data);
export const deleteRiskRating = (id: any) =>
  api.delete(url.DELETE_RISK_RATING + "/" + id);

// Risk Assessment Files
export const getRestrictedFiles = (params: any) =>
  api.get(
    `${url.GET_RESTRICTED_FILES}/${params.id}/download-restricted/${params?.fileId}`,
    params
  );
export const getConfidentialFiles = (params: any) =>
  api.get(
    `${url.GET_CONFIDENTIAL_FILES}/${params.id}/download-confidential/${params?.fileId}`,
    params
  );
