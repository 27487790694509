import React from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import AllPersons from "./AllPersons";

const Persons = () => {
  document.title = "Persons | CIMS";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Persons"
            pageTitles={[{ title: "Dashboard", link: "/dashboard" }]}
          />
          <AllPersons />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Persons;
