import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import RiskAssessmentForm from "./RiskAssessmentForm";
import { updateRiskAssessment } from "common/services/risk-assessments.service";

const UpdateRiskAssessment: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchUpdatedData: () => void;
  selectedRecord: any;
}> = ({ toggle, isModalOpen, fetchUpdatedData, selectedRecord }) => {
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      reportTitle: selectedRecord?.reportTitle,
      agency: {
        value: selectedRecord?.agency?.id,
        label: selectedRecord?.agency?.name,
      },
      contractor: {
        value: selectedRecord?.contractor?.id,
        label: selectedRecord?.contractor?.contractorName,
      },
      serialNumber: selectedRecord?.serialNumber,
      reportDate:
        (selectedRecord?.reportDate && new Date(selectedRecord?.reportDate)) ||
        null,
      overallRiskRating: {
        value: selectedRecord?.overallRiskRating?.id,
        label: selectedRecord?.overallRiskRating?.name,
      },
      conductedBy: {
        value: selectedRecord?.conductedBy?.id,
        label:
          selectedRecord?.conductedBy?.firstName +
          " " +
          selectedRecord?.conductedBy?.lastName,
      },
      summaryOfFindings: selectedRecord?.summaryOfFindings,
      sensitivity: {
        value: selectedRecord?.sensitivity || "",
        label: selectedRecord?.sensitivity || "",
      },
      selectedFiles:
        (selectedRecord?.reportFile &&
          selectedRecord?.reportFile?.map((file: any) => {
            return {
              id: file.id,
              name: file.name,
              formattedSize: file.size + " KB",
            };
          })) ||
        [],
    },
    validationSchema: Yup.object({
      reportTitle: Yup.string().required("Please enter report title"),
      agency: Yup.object().shape({
        value: Yup.number(),
      }),
      contractor: Yup.object().shape({
        value: Yup.number().required("Please select contractor"),
      }),
      serialNumber: Yup.string().required("Please enter serial number"),
      reportDate: Yup.string().required("Please select report date"),
      overallRiskRating: Yup.object().shape({
        value: Yup.number().required("Please select overall risk rating"),
      }),
      conductedBy: Yup.object().shape({
        value: Yup.number().required("Please select conducted by"),
      }),
      summaryOfFindings: Yup.string().required(
        "Please enter summary of findings"
      ),
      sensitivity: Yup.object().shape({
        value: Yup.string().nullable(),
      }),
      selectedFiles: Yup.array(),
      // .min(1, "Atleast one file is required")
      // .required("Required"),
    }),
    onSubmit: async (values: any) => {
      const data = {
        reportTitle: values.reportTitle,
        agency: values.agency.value,
        contractor: values.contractor.value,
        serialNumber: values.serialNumber,
        reportDate:
          values.reportDate && values?.reportDate?.toLocaleDateString("en-CA"),
        overallRiskRating: values.overallRiskRating.value,
        conductedBy: values.conductedBy.value,
        summaryOfFindings: values.summaryOfFindings,
        sensitivity: values.sensitivity.value,
        reportFile: validation.values.selectedFiles.filter((file: any) => {
          if (file?.id) {
            return file.id;
          }
          return false;
        }),
      };

      const formData = new FormData();
      values.selectedFiles.forEach((file: any) => {
        if (!file?.id) {
          formData.append("files.reportFile", file, file.name);
        }
      });
      formData.append("data", JSON.stringify(data));

      setLoading(true);
      try {
        setLoading(true);
        await updateRiskAssessment({
          id: selectedRecord.id,
          formData,
        });
        fetchUpdatedData();
        validation.resetForm();
        toggle();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
  });

  // console.log("selectedRecord", selectedRecord.reportFile);

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Risk Assessment
      </ModalHeader>

      <RiskAssessmentForm
        validation={validation}
        isUpdate
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
        selectedRecord={selectedRecord}
      />
    </Modal>
  );
};

export default UpdateRiskAssessment;
