import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col, Table, CardHeader } from "reactstrap";
import { createSelector } from "reselect";
import UpdateContractor from "./UpdateContractor";
import { getContractor as onGetContractor } from "slices/thunks";
import Permission from "Components/Common/Permission";

const Summary = ({ contractor }: { contractor: any }) => {
  const selectProperties = createSelector(
    (state: any) => state.Contractors,
    (state) => ({
      performanceRatings: state.contractor?.performanceRatings || [],
      loading: state.loading,
    })
  );
  const { performanceRatings: data } = useSelector(selectProperties);

  const satisfactoryCount = useMemo(() => {
    return data.filter(
      (rating: any) => rating.performanceRatingType?.name === "Satisfactory"
    ).length;
  }, [data]);
  const notSatisfactoryCount = useMemo(() => {
    return data.filter(
      (rating: any) => rating.performanceRatingType?.name === "Not Satisfactory"
    ).length;
  }, [data]);
  const suspendedCount = useMemo(() => {
    return data.filter(
      (rating: any) => rating.performanceRatingType?.name === "Suspended"
    ).length;
  }, [data]);
  const globallySuspendedCount = useMemo(() => {
    return data.filter(
      (rating: any) =>
        rating.performanceRatingType?.name === "Globally Suspended"
    ).length;
  }, [data]);

  const dispatch: any = useDispatch();

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  return (
    <Card>
      <CardHeader className="d-flex align-items-center border-bottom-dashed">
        <div className="text-muted flex-grow-1 d-flex align-items-center">
          <h6 className="mb-0 fw-semibold text-uppercase">Summary</h6>
        </div>
        <div className="flex-shrink-0 d-flex align-items-center">
          <Permission resource={"contractor"} action={"update"}>
            <Link
              to="#"
              onClick={(e) => {
                e.stopPropagation(); // Prevent collapse toggle on edit click
                showUpdateModal();
              }}
              className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover me-3"
            >
              <i className="ri-pencil-line"></i> Edit
            </Link>
          </Permission>
        </div>
      </CardHeader>
      <CardBody className="card-body">
        <Row>
          <Col md={6}>
            <div className="table-responsive border border-dashed px-2 py-2">
              <Table className="align-top table-nowrap table-borderless table-sm">
                <tbody>
                  <tr>
                    <td className="fw-medium"> Contractor Name:</td>
                    <td>
                      <div style={{ whiteSpace: "pre-wrap", width: "auto" }}>
                        {contractor?.contractorName}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium"> Organization Type:</td>
                    <td>
                      <div style={{ whiteSpace: "pre-wrap", width: "auto" }}>
                        {contractor?.organizationType?.name}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium"> Acronym:</td>
                    <td>{contractor?.acronym}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium"> Alias Names:</td>
                    <td>
                      {contractor?.previousOrAliasName
                        ?.map((el: any) => el.name)
                        .join(", ")}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md={6}>
            <div className="mt-0 py-3">
              {/* <div className="text-muted">
                <h6 className="fw-semibold">Performance Ratings:</h6>
              </div> */}
              <Row className="align-items-center g-2">
                <div className="col-auto">
                  <div className="p-2">
                    <h6 className="mb-0">Satisfactory</h6>
                  </div>
                </div>
                <div className="col">
                  <div className="p-2">
                    <div className="progress animated-progess progress-sm">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{ width: "100%" }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="p-2">
                    <h6 className="mb-0 text-muted">{satisfactoryCount}</h6>
                  </div>
                </div>
              </Row>
              <Row className="align-items-center g-2">
                <div className="col-auto">
                  <div className="p-2">
                    <h6 className="mb-0">Not Satisfactory</h6>
                  </div>
                </div>
                <div className="col">
                  <div className="p-2">
                    <div className="progress animated-progess progress-sm">
                      <div
                        className="progress-bar bg-warning"
                        role="progressbar"
                        style={{ width: "100%" }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="p-2">
                    <h6 className="mb-0 text-muted">{notSatisfactoryCount}</h6>
                  </div>
                </div>
              </Row>

              <Row className="align-items-center g-2">
                <div className="col-auto">
                  <div className="p-2">
                    <h6 className="mb-0">Suspended</h6>
                  </div>
                </div>
                <div className="col">
                  <div className="p-2">
                    <div className="progress animated-progess progress-sm">
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        style={{ width: "100%" }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="p-2">
                    <h6 className="mb-0 text-muted">{suspendedCount}</h6>
                  </div>
                </div>
              </Row>

              <Row className="align-items-center g-2">
                <div className="col-auto">
                  <div className="p-2">
                    <h6 className="mb-0">Globally Suspended</h6>
                  </div>
                </div>
                <div className="col">
                  <div className="p-2">
                    <div className="progress animated-progess progress-sm">
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        style={{ width: "100%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="col-auto">
                  <div className="p-2">
                    <h6 className="mb-0 text-muted">
                      {globallySuspendedCount}
                    </h6>
                  </div>
                </div>
              </Row>
              <Row className="py-2"></Row>
            </div>
          </Col>
        </Row>
      </CardBody>
      <UpdateContractor
        isModalOpen={isUpdateModalOpen}
        toggle={() => setIsUpdateModalOpen((state) => !state)}
        contractor={contractor}
        fetchData={() => {
          dispatch(
            onGetContractor({
              id: contractor?.id,
            })
          );
        }}
      />
    </Card>
  );
};

export default Summary;
