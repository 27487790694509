import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";

import Header from "./Header";
import MainDetails from "./MainDetails";
import AdditionalDetails from "./AdditionalDetails";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { getUNGMItem as onGetUNGMItem } from "slices/thunks";
import BreadCrumb from "Components/Common/BreadCrumb";

const ViewUNGMItem = () => {
  const dispatch: any = useDispatch();

  const { id: itemId } = useParams();

  useEffect(() => {
    dispatch(onGetUNGMItem(itemId));
  }, [itemId, dispatch]);

  const selectLayoutState = (state: any) => state.SanctionsList;
  const selectProperties = createSelector(selectLayoutState, (state) => ({
    ungmItem: state.ungmItem,
  }));

  const { ungmItem } = useSelector(selectProperties);

  const [isWorldBankItem, setIsWorldBankItem] = useState(false);
  useEffect(() => {
    if (ungmItem.dataSource === "World Bank") {
      setIsWorldBankItem(true);
    } else {
      setIsWorldBankItem(false);
    }
  }, [ungmItem.dataSource]);

  document.title = "UNGM Debarred Item | CIMS";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {isWorldBankItem ? (
            <BreadCrumb
              title="World Bank Sanction Details"
              pageTitles={[
                {
                  title: "Dashboard",
                  link: "/",
                },
                {
                  title: "World Bank Debarred List",
                  link: "/sanctions-list/world-bank",
                },
              ]}
              back
            />
          ) : (
            <BreadCrumb
              title="UNGM Sanction Details"
              pageTitles={[
                {
                  title: "Dashboard",
                  link: "/",
                },
                { title: "UNGM Debarred List", link: "/sanctions-list/ungm" },
              ]}
              back
            />
          )}
          <Row>
            <Header data={ungmItem} />
          </Row>
          <Row>
            <MainDetails data={ungmItem} />
            <AdditionalDetails data={ungmItem} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewUNGMItem;
